import * as Yup from 'yup'

export const createUserValidationSchema = Yup.object().shape({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  education_level: Yup.string().required('Education level is required'),
  social_situation: Yup.string().required('Social situation is required'),
  email: Yup.string().email('Invalid email format').required('Email is required'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
  admin_site_id: Yup.string().required('Site are required'),
})
