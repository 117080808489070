import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest, select, takeEvery} from 'redux-saga/effects'
import {
  getJobs,
  getTimeSlots,
  createJob,
  getJobsByFilter,
  deleteJob,
  updateJob,
  getJobById,
  getJobApplications,
  getCompanies,
} from './JobCRUD'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  getTimeSlots: '[getTimeSlots] Action',
  getTimeSlotsSucceded: '[getTimeSlotsSucceded] Action',
  getTimeSlotsFailed: '[getTimeSlotsFailed] Action',
  getJobs: '[getJobs] Action',
  getJobsSucceded: '[getJobsSucceded] Action',
  getJobsFailed: '[getJobsFailed] Action',
  getJobsByFilter: '[getJobsByFilter] Action',
  getJobsByFilterSucceded: '[getJobsByFilterSucceded] Action',
  getJobsByFilterFailed: '[getJobsByFilterFailed] Action',
  createJob: '[createJob] Action',
  createJobSucceded: '[createJobSucceded] Action',
  createJobFailed: '[createJobFailed] Action',
  updateJob: '[updateJob] Action',
  updateJobSucceded: '[updateJobSucceded] Action',
  updateJobFailed: '[updateJobFailed] Action',
  deleteJob: '[deleteJob] Action',
  deleteJobSucceded: '[deleteJobSucceded] Action',
  deleteJobFailed: '[deleteJobFailed] Action',
  getJob: '[getJob] Action',
  getJobSucceded: '[getJobSucceded] Action',
  getJobFailed: '[getJobFailed] Action',
  getCompanies: '[getCompanies] Action',
  getCompaniesSucceded: '[getCompaniesSucceded] Action',
  getCompaniesFailed: '[getCompaniesFailed] Action',
  getJobApplications: '[getJobApplications] Action',
  getJobApplicationsSucceded: '[getJobApplicationsSucceded] Action',
  getJobApplicationsFailed: '[getJobApplicationsFailed] Action',
  resetMessages: '[resetMessages] Action',
  resetMessagesSuccess: '[resetMessagesSuccess] Action',
  getCategories: '[getCategories] Action',
  getCategoriesSucceded: '[getCategoriesSucceded] Action',
  getCategoriesFailed: '[getCategoriesFailed] Action',
}

const initialAuthState: JobState = {
  jobs: [],
  timeSlots: [],
  companies: [],
  job: {},
  categories: [],
  applications: [],
  loading: false,
  success: '',
  failed: '',
}

export interface JobState {
  jobs?: any
  timeSlots?: any
  companies: any
  job?: any
  categories?: any
  applications?: any
  loading?: boolean
  success?: string
  failed?: string
}

export const reducer = persistReducer(
  {storage, key: 'v100-demo1-auth', whitelist: ['user', 'accessToken']},
  (state: any = initialAuthState, action: ActionWithPayload<any>) => {
    switch (action.type) {
      case actionTypes.getTimeSlots: {
        return {...state, loading: true, success: '', failed: ''}
      }
      case actionTypes.getTimeSlotsSucceded: {
        const timeSlots = action.payload.data
        return {...state, timeSlots, loading: false, success: 'time_slots'}
      }
      case actionTypes.getTimeSlotsFailed: {
        return {...state, failed: 'time_slots', loading: false}
      }
      case actionTypes.getJobs: {
        return {...state, loading: true, success: '', failed: ''}
      }
      case actionTypes.getJobsSucceded: {
        const jobs = action.payload.data
        return {...state, jobs, loading: false, success: 'get_jobs'}
      }
      case actionTypes.getJobsFailed: {
        return {...state, failed: 'get_jobs', loading: false}
      }
      case actionTypes.getCompanies: {
        return {...state, loading: true, success: '', failed: ''}
      }
      case actionTypes.getCompaniesSucceded: {
        const companies = action.payload.data
        return {...state, companies, loading: false, success: 'get_companies'}
      }
      case actionTypes.getCompaniesFailed: {
        return {...state, failed: 'get_companies', loading: false}
      }
      case actionTypes.getJob: {
        return {...state, loading: true, success: '', failed: ''}
      }
      case actionTypes.getJobSucceded: {
        const job = action.payload.data
        return {...state, job, loading: false, success: 'get_job'}
      }
      case actionTypes.getJobFailed: {
        return {...state, failed: 'get_job', loading: false}
      }
      case actionTypes.getJobApplications: {
        return {...state, loading: true, success: '', failed: ''}
      }
      case actionTypes.getJobApplicationsSucceded: {
        const applications = action.payload.data
        return {...state, applications, loading: false, success: 'get_applications'}
      }
      case actionTypes.getJobApplicationsFailed: {
        return {...state, failed: 'get_applications', loading: false}
      }
      case actionTypes.deleteJob: {
        return {...state, loading: true, success: '', failed: ''}
      }
      case actionTypes.deleteJobSucceded: {
        return {
          ...state,
          jobs: [...state.jobs.filter((el: any) => el.id !== action.payload)],
          loading: false,
          success: 'delete',
        }
      }
      case actionTypes.deleteJobFailed: {
        return {...state, failed: 'delete', loading: false}
      }
      case actionTypes.updateJob: {
        return {...state, loading: true, success: '', failed: ''}
      }
      case actionTypes.updateJobSucceded: {
        const updatedJob = action.payload.data
        // console.log("state", state)
        // let jobsCopy = [...state.jobs]
        // console.log("jobs before", jobsCopy)
        // let index = state.jobs.findIndex((job:any) => job.id != updatedJob.id)
        // let updatedArray = jobsCopy.splice(index, 1, updateJob)
        //console.log("after",jobs, updatedJob, )
        return {
          ...state,
          jobs: state.jobs.map((item: any) => {
            if (item.id == updatedJob.id) return {...item, ...updatedJob}
            return item
          }),
          loading: false,
          success: 'update',
        }
      }
      case actionTypes.updateJobFailed: {
        return {...state, failed: 'update', loading: false}
      }
      case actionTypes.getJobsByFilter: {
        return {...state, loading: true, success: '', failed: ''}
      }
      case actionTypes.getJobsByFilterSucceded: {
        const jobs = action.payload
        return {...state, jobs: jobs, loading: false, success: 'filter'}
      }
      case actionTypes.getJobsByFilterFailed: {
        return {...state, failed: 'filter', loading: false}
      }
      case actionTypes.createJobSucceded: {
        const job = action.payload.data
        return {...state, jobs: [...state.jobs, job], loading: false, success: 'create'}
      }
      case actionTypes.createJobFailed: {
        return {...state, loading: false, failed: 'create'}
      }
      case actionTypes.createJob: {
        return {...state, loading: true, success: '', failed: ''}
      }
      case actionTypes.getCategoriesSucceded: {
        const categories = action.payload.data
        return {...state, categories}
      }
      case actionTypes.resetMessagesSuccess: {
        return {...state, loading: false, success: '', failed: ''}
      }
      default:
        return state
    }
  }
)

export const actions = {
  getCategoriesSucceded: (data: any) => ({type: actionTypes.getCategoriesSucceded, payload: data}),
  getCategories: (data: any) => ({type: actionTypes.getCategories, payload: data}),
  getJobsByFilterSucceded: (data: any) => ({
    type: actionTypes.getJobsByFilterSucceded,
    payload: data,
  }),
  getJobsByFilter: (data: any) => ({type: actionTypes.getJobsByFilter, payload: data}),
  getJobsByFilterFailed: () => ({type: actionTypes.getJobsByFilterFailed}),
  getTimeSlotsSucceded: (data: any) => ({type: actionTypes.getTimeSlotsSucceded, payload: data}),
  getTimeSlots: (data: any) => ({type: actionTypes.getTimeSlots, payload: data}),

  getJobsSucceded: (data: any) => ({type: actionTypes.getJobsSucceded, payload: data}),
  getJobs: (data: any) => ({type: actionTypes.getJobs, payload: data}),
  getJob: (id: any) => ({type: actionTypes.getJob, payload: id}),
  getJobSucceded: (data: any) => ({type: actionTypes.getJobSucceded, payload: data}),
  getJobFailed: () => ({type: actionTypes.getJobFailed}),
  createJob: (data: any) => ({type: actionTypes.createJob, payload: data}),
  createJobSucceded: (data: any) => ({type: actionTypes.createJobSucceded, payload: data}),
  createJobFailed: () => ({type: actionTypes.createJobFailed}),
  deleteJob: (id: any) => ({type: actionTypes.deleteJob, payload: id}),
  deleteJobSucceded: (id: any) => ({type: actionTypes.deleteJobSucceded, payload: id}),
  deleteJobFailed: () => ({type: actionTypes.deleteJobFailed}),
  updateJob: (data: any) => ({type: actionTypes.updateJob, payload: data}),
  updateJobSucceded: (data: any) => ({type: actionTypes.updateJobSucceded, payload: data}),
  updateJobFailed: () => ({type: actionTypes.updateJobFailed}),
  getJobApplications: (filters: any) => ({type: actionTypes.getJobApplications, payload: filters}),
  getJobApplicationsSucceded: (data: any) => ({
    type: actionTypes.getJobApplicationsSucceded,
    payload: data,
  }),
  getJobApplicationsFailed: () => ({type: actionTypes.getJobApplicationsFailed}),
  getCompanies: () => ({type: actionTypes.getCompanies}),
  getCompaniesSucceded: (data: any) => ({type: actionTypes.getCompaniesSucceded, payload: data}),
  getCompaniesFailed: () => ({type: actionTypes.getCompaniesFailed}),
  resetMessages: () => ({type: actionTypes.resetMessages}),
  resetMessagesSuccess: () => ({type: actionTypes.resetMessagesSuccess}),
}

export function* saga() {
  yield takeEvery(actionTypes.getCategories, function* getCategoriesSaga(id: any) {
    try {
      //@ts-ignore
      const {data} = yield getCategories(id?.payload)
      yield put(actions.getCategoriesSucceded(data))
    } catch (e) {
      yield put(actions.getJobFailed())
    }
  })
  yield takeEvery(actionTypes.getTimeSlots, function* getTimeSlotsSaga(filter: any) {
    try {
      //@ts-ignore
      const {data} = yield getTimeSlots(filter?.payload?.value)
      yield put(actions.getTimeSlotsSucceded(data))
    } catch (e) {
      yield put(actions.getJobFailed())
    }
  })
  yield takeEvery(actionTypes.getCompanies, function* getCompaniesSaga() {
    try {
      //@ts-ignore
      const {data} = yield getCompanies()
      yield put(actions.getCompaniesSucceded(data))
    } catch (e) {
      yield put(actions.getJobFailed())
    }
  })
  yield takeEvery(actionTypes.getJobs, function* getJobsSaga(filter: any) {
    try {
      //@ts-ignore
      const {data} = yield getJobs(filter?.payload?.draft, filter?.payload?.value)
      yield put(actions.getJobsSucceded(data))
    } catch (e) {
      yield put(actions.getJobFailed())
    }
  })
  yield takeEvery(actionTypes.getJob, function* getJobSaga(id: any) {
    try {
      const job: {} = yield getJobById(id?.payload)
      yield put(actions.getJobSucceded(job))
    } catch (e) {
      yield put(actions.getJobFailed())
    }
  })
  yield takeEvery(actionTypes.getJobApplications, function* getJobApplicationsSaga(filters: any) {
    try {
      //@ts-ignore
      const {data} = yield getJobApplications(filters?.payload?.id, filters?.payload?.filter)
      yield put(actions.getJobApplicationsSucceded(data))
    } catch (e) {
      yield put(actions.getJobApplicationsFailed())
    }
  })

  yield takeEvery(actionTypes.createJob, function* createJobSaga(data: any) {
    try {
      console.log('---redux---', data.payload)
      const createdData: {} = yield createJob(data?.payload)
      yield put(actions.createJobSucceded(createdData))
    } catch (e) {
      yield put(actions.createJobFailed())
    }
  })

  yield takeEvery(actionTypes.deleteJob, function* deleteJobSaga(id: any) {
    try {
      const response: {} = yield deleteJob(id?.payload)
      yield put(actions.deleteJobSucceded(id?.payload))
    } catch (e) {
      yield put(actions.deleteJobFailed())
    }
  })
  yield takeEvery(actionTypes.updateJob, function* updateJobSaga(data: any) {
    try {
      const updatedData: {} = yield updateJob(data?.payload?.id, data?.payload?.data)
      yield put(actions.updateJobSucceded(updatedData))
    } catch (e) {
      yield put(actions.updateJobFailed())
    }
  })
  yield takeEvery(actionTypes.getJobsByFilter, function* getJobsByFilterSaga(filters: any) {
    try {
      const {
        data: {data},
      } = yield getJobsByFilter(
        filters?.payload?.company_id,
        filters?.payload?.draft,
        filters?.payload?.title,
        filters?.payload?.status,
        filters?.payload?.site_id
      )
      yield put(actions.getJobsByFilterSucceded(data))
    } catch (e) {
      yield put(actions.getJobsByFilterFailed())
    }
  })
  yield takeEvery(actionTypes.resetMessages, function* resetMessagesSaga() {
    yield put(actions.resetMessagesSuccess())
  })
}
