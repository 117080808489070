import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { Overview } from './components/accounts/components/Overview'
import { Settings } from './components/accounts/components/settings/Settings'
import {User} from './components/user/User'


export function UserPage() {
  const profileBreadCrumbs: Array<PageLink> = [
    {
      title: 'User',
      path: '/crafted/pages/user/list',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  return (
    <>
      <Switch>
        <Route path='/crafted/pages/user/list' exact={true}>
          <PageTitle  breadcrumbs={profileBreadCrumbs}>Users</PageTitle>
          <User />
        </Route>
        <Route path='/crafted/pages/user/:id/overview' exact={true}>
          <PageTitle breadcrumbs={profileBreadCrumbs}>Overview</PageTitle>
          <Overview />
        </Route>
        <Route path='/crafted/pages/user/:id/settings' exact={true}>
          <PageTitle breadcrumbs={profileBreadCrumbs}>Settings</PageTitle>
          <Settings />
        </Route>
        <Redirect from='/crafted/pages/user' exact={true} to='/crafted/pages/user/list' />
      </Switch>
    </>
  )
}

export default UserPage
