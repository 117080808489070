import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest, select, takeEvery} from 'redux-saga/effects'
import {editUser, getUserApi, getUsersApi} from "./service";
export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  getUsers: '[getUsers] Action',
  getUsersSucceded: '[getUsersSucceded] Action',
  getUsersFailed: '[getUsersFailed] Action',
  getUser: '[getUser] Action',
  getUserSucceded: '[getUserSucceded] Action',
  getUserFailed: '[getUserFailed] Action',
  editUser: '[editUser] Action',
  editUserSucceded: '[editUserSucceded] Action',
  editUserFailed: '[editUserFailed] Action' 
}

const initialCategoriesState: any = {
  current_page : null,
  first_page : null,
  last_page : null,
  usersList : []
}

export const reducer = persistReducer(
  {storage, key: 'v100-demo1-auth', whitelist: ['user', 'accessToken']},
  (state: any = initialCategoriesState, action: ActionWithPayload<any>) => {
    switch (action.type) {
      case actionTypes.getUsersSucceded: {
        const usersList = action.payload?.data
        return {...state, usersList, first_page: action.payload?.meta?.first_page, last_page: action.payload?.meta?.last_page, current_page: action.payload?.meta?.current_page}
      }
      case actionTypes.getUserSucceded: {
        const user = action.payload?.data
        return {...state, user}
      }
      case actionTypes.editUserSucceded: {
        return {...state,usersList: [...state.usersList.filter((el: any)=> el.id != action?.payload?.data?.id), action.payload.data]}
      }
      default:
        return state
    }
  }
)

export const actions = {
  getUsersSucceded: (data : any) => ({type: actionTypes.getUsersSucceded, payload: data}),
  getUsers: (filter?: any)  => ({type: actionTypes.getUsers, payload: filter}),
  getUserSucceded: (data : any) => ({type: actionTypes.getUserSucceded, payload: data}),
  getUser: (data : any)  => ({type: actionTypes.getUser, payload: data}),
  editUser: (data : any) => ({type: actionTypes.editUser, payload: data}),
  editUserSucceded: (data : any) => ({type: actionTypes.editUserSucceded, payload: data}),
  store: () => ({type: "def"}),
}

export function* saga() {
  yield takeEvery(actionTypes.getUsers, function* getUsersSaga(payload: any) {
    const {data} = yield getUsersApi(payload?.payload?.page, payload?.payload?.filter)
    yield put(actions.getUsersSucceded(data))
  })
  yield takeEvery(actionTypes.getUser, function* getUserSaga(data: any) {
    const user : any = {} = yield getUserApi(data?.payload)
    yield put(actions.getUserSucceded(user))
  })
  yield takeEvery(actionTypes.editUser, function* editUserSaga(data: any) {
    const createdData :{} = yield editUser(data?.payload)
    yield put(actions.editUserSucceded(createdData))
  })
}
