import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
export const CityURL = `${API_URL}/cities/`
export const CountryURL = `${API_URL}/countries/`
export const DataURL = `${API_URL}/metadata/`
export const uploadURL = `${API_URL}/metadata/upload`
export const GetSkillURL = `${API_URL}/metadata?filter={"type":{"op":"=","value":"skill"}}`
export const GetBenefitURL = `${API_URL}/metadata?filter={"type":{"op":"=","value":"benefit"}}`
export const GetUniversityURL = `${API_URL}/metadata?filter={"type":{"op":"=","value":"university"}}`
export const GetCompanyURL = `${API_URL}/metadata?filter={"type":{"op":"=","value":"company"}}`
export const GetSpecialityURL = `${API_URL}/metadata?filter={"type":{"op":"=","value":"speciality"}}`
export const GetDiplomeURL = `${API_URL}/metadata?filter={"type":{"op":"=","value":"diplome"}}`
export const GetWorkPosteURL = `${API_URL}/metadata?filter={"type":{"op":"=","value":"workPoste"}}`
export const GetContractTypeURL = `${API_URL}/metadata?filter={"type":{"op":"=","value":"contractType"}}`
export const GetJobTitleURL = `${API_URL}/metadata?filter={"type":{"op":"=","value":"jobTitle"}}`
export const SiteURL = `${API_URL}/admin-sites`
export const IAMUsersURL = `${API_URL}/users/?filter={"role":{"op":"=","value":"admin"}}`
export const IAMUserURL = `${API_URL}/users/`
export function getSkillsApi(page: any, filter?: any) {
  if (!filter) return axios.get(GetSkillURL)
  else return axios.get(`${API_URL}/metadata/paginate?filter=${filter}&page=${page}&perPage=20`)
}
export function getBenefitsApi(page: any, filter?: any) {
  if (!filter) return axios.get(GetBenefitURL)
  else return axios.get(`${API_URL}/metadata/paginate?filter=${filter}&page=${page}&perPage=20`)
}
export function getUniversitiesApi(page: any, filter?: any) {
  if (!filter) return axios.get(GetUniversityURL)
  else return axios.get(`${API_URL}/metadata/paginate?filter=${filter}&page=${page}&perPage=20`)
}
export function getCompaniesDataApi(page: any, filter?: any) {
  if (!filter) return axios.get(GetCompanyURL)
  else return axios.get(`${API_URL}/metadata/paginate?filter=${filter}&page=${page}&perPage=100`)
}
export function getSpecialitiesApi(page: any, filter?: any) {
  if (!filter) return axios.get(GetSpecialityURL)
  else return axios.get(`${API_URL}/metadata/paginate?filter=${filter}&page=${page}&perPage=20`)
}
export function getDiplomesApi(page: any, filter?: any) {
  if (!filter) return axios.get(GetDiplomeURL)
  else return axios.get(`${API_URL}/metadata/paginate?filter=${filter}&page=${page}&perPage=20`)
}
export function getWorkPostesApi(page: any, filter?: any) {
  if (!filter) return axios.get(GetWorkPosteURL)
  else return axios.get(`${API_URL}/metadata/paginate?filter=${filter}&page=${page}&perPage=20`)
}
export function getContractTypesApi(page: any, filter?: any) {
  if (!filter) return axios.get(GetContractTypeURL)
  else return axios.get(`${API_URL}/metadata/paginate?filter=${filter}&page=${page}&perPage=20`)
}
export function getJobTitlesApi() {
  return axios.get(GetJobTitleURL)
}
export function getData(id: any) {
  return axios.get(DataURL + id)
}

export function createData(name: string, name_ar: string, type: string, parent_id?: number) {
  return axios.post<any>(DataURL, {
    name: name,
    name_ar: name_ar,
    type: type,
    parent_id: parent_id,
  })
}

export function editData(data: any) {
  return axios.patch<any>(DataURL + data.id, data)
}
export function deleteData(id: any) {
  return axios.delete<any>(DataURL + id)
}
export function getCitiesApi(page: any, filter?: any) {
  return axios.get(`${API_URL}/cities/paginate?page=${page}&perPage=20`)
}
export function getCity(id: any) {
  return axios.get(CityURL + id)
}

export function createCity(data: any) {
  return axios.post<any>(CityURL, data)
}

export function editCity(data: any) {
  return axios.patch<any>(CityURL + data.id, data)
}
export function deleteCity(id: any) {
  return axios.delete<any>(CityURL + id)
}

export function getCountriesApi(page: any, filter?: any) {
  return axios.get(`${API_URL}/countries/paginate?page=${page}&perPage=20`)
}
export function getCountry(id: any) {
  return axios.get(CountryURL + id)
}

export function createCountry(data: any) {
  return axios.post<any>(CountryURL, data)
}
export function uploadDataApi(data: any) {
  return axios.post<any>(uploadURL, data)
}

export function editCountry(data: any) {
  return axios.patch<any>(CountryURL + data.id, data)
}
export function deleteCountry(id: any) {
  return axios.delete<any>(CountryURL + id)
}

export function createSite(data: any) {
  return axios.post<any>(SiteURL, data)
}

export function getSitesApi(page: any, filter?: any) {
  return axios.get(`${API_URL}/admin-sites?page=${page}&perPage=20`)
}

export function getIAMUsersApi(page: any) {
  return axios.get(
    `${API_URL}/users/paginate/?page=${page}&perPage=100&filter={"role":{"op":"=","value":"admin"}}`
  )
}
export function getIAMUserApi(data: any) {
  return axios.get<any>(IAMUserURL + data.id)
}
export function createIAMUser(data: any) {
  return axios.post<any>(IAMUserURL + 'admin', data)
}
