import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest, select, takeEvery} from 'redux-saga/effects'
import {
  createRole,
  deleteRole,
  editRole,
  editCompanyUser,
  getPermissionsApi,
  getRoleApi,
  getRolesApi,
  getCompanyUserApi,
  getCompanyUsersApi,
  deleteCompanyApi,
  editCompanyApi,
  getCompaniesApi,
  getCompanyApi,
  getCountries,
  getCountriesApi,
  register,
  getCategories,
  getMetaData,
  getSites,
} from './service'
export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  getCompanyUsers: '[getCompanyUsers] Action',
  getCompanyUsersSucceded: '[getCompanyUsersSucceded] Action',
  getCompanyUsersFailed: '[getCompanyUsersFailed] Action',
  getCompanyUser: '[getCompanyUser] Action',
  getCompanyUserSucceded: '[getCompanyUserSucceded] Action',
  getCompanyUserFailed: '[getCompanyUserFailed] Action',
  editCompanyUser: '[editCompanyUser] Action',
  editCompanyUserSucceded: '[editCompanyUserSucceded] Action',
  editCompanyUserFailed: '[editCompanyUserFailed] Action',
  getPermissions: '[getPermissions] Action',
  getPermissionsSucceded: '[getPermissionsSucceded] Action',
  getPermissionsFailed: '[getPermissionsFailed] Action',
  getRoles: '[getRoles] Action',
  getRolesSucceded: '[getRolesSucceded] Action',
  getRolesFailed: '[getRolesFailed] Action',
  getRole: '[getRole] Action',
  getRoleSucceded: '[getRolesucceded] Action',
  getRoleFailed: '[getRoleFailed] Action',
  createRole: '[createRole] Action',
  editRole: '[editRole] Action',
  editRoleSucceded: '[editRoleSucceded] Action',
  editRoleFailed: '[editRoleFailed] Action',
  createRoleSucceded: '[createRoleSucceded] Action',
  createRoleFailed: '[createRoleFailed] Action',
  deleteRole: '[deleteRole] Action',
  deleteRoleSucceded: '[deleteRoleSucceded] Action',
  deleteRoleFailed: '[deleteRoleFailed] Action',
  getCompanies: '[getCompanies] Action',
  getCompaniesSucceded: '[getCompaniesSucceded] Action',
  getCompaniesFailed: '[getCompaniesFailed] Action',
  getCompany: '[getCompany] Action',
  getCompanySucceded: '[getCompaniesucceded] Action',
  getCompanyFailed: '[getCompanyFailed] Action',

  editCompany: '[editCompany] Action',
  editCompanySucceded: '[editCompanySucceded] Action',
  editCompanyFailed: '[editCompanyFailed] Action',

  deleteCompany: '[deleteCompany] Action',
  deleteCompanySucceded: '[deleteCompanySucceded] Action',
  deleteCompanyFailed: '[deleteCompanyFailed] Action',
  getCountry: '[getCountry] Action',
  getCountrySucceded: '[getCountrySucceded] Action',
  getCountryFailed: '[getCountryFailed] Action',
  getCountries: '[getCountries] Action',
  getCountriesSucceded: '[getCountriesSucceded] Action',
  getCountriesFailed: '[getCountriesFailed] Action',
  getCategories: '[getCategories] Action',
  getCategoriesSucceded: '[getCategoriesSucceded] Action',
  getCategoriesFailed: '[getCategoriesFailed] Action',
  createCompany: '[createCompany] Action',
  createCompanySucceded: '[createCompanySucceded] Action',
  createCompanyFailed: '[createCompanyFailed] Action',
  getMetaData: '[getMetaData] Action',
  getMetaDataSucceded: '[getMetaDataSucceded] Action',
  getMetaDataFailed: '[getMetaDataFailed] Action',
  getSites: '[getSites] Action',
  getSitesSucceded: '[getSitesSucceded] Action',
  getSitesFailed: '[getSitesFailed] Action',
}

const initialCategoriesState: any = {
  current_page: null,
  first_page: null,
  last_page: null,
  usersList: [],
  companiesList: [],
  countriesList: [],
  country: {},
  categories: [],
  rolesList: [],
  permissionsList: [],
  role: {},
  companyUser: {},
  company: {},
  metaData: [],
}

export const reducer = persistReducer(
  {storage, key: 'v100-demo1-auth', whitelist: ['user', 'accessToken']},
  (state: any = initialCategoriesState, action: ActionWithPayload<any>) => {
    switch (action.type) {
      case actionTypes.getCompanyUsersSucceded: {
        const usersList = action.payload?.data
        return {
          ...state,
          usersList,
          first_page: action.payload?.meta?.first_page,
          last_page: action.payload?.meta?.last_page,
          current_page: action.payload?.meta?.current_page,
        }
      }
      case actionTypes.getCompanyUserSucceded: {
        const companyUser = action.payload?.data
        return {...state, companyUser}
      }
      case actionTypes.editCompanyUserSucceded: {
        return {
          ...state,
          usersList: [
            ...state.usersList.filter((el: any) => el.id != action?.payload?.data?.id),
            action.payload.data,
          ],
        }
      }
      case actionTypes.getCompaniesSucceded: {
        const companiesList = action.payload?.data
        const company = {}
        return {
          ...state,
          companiesList,
          company,
          first_page: action.payload?.meta?.first_page,
          last_page: action.payload?.meta?.last_page,
          current_page: action.payload?.meta?.current_page,
        }
      }
      case actionTypes.getCompanySucceded: {
        const company = action.payload?.data
        return {...state, company}
      }
      case actionTypes.getCompanyFailed: {
        return {...state, error: 'get', loading: false}
      }
      case actionTypes.getCountriesFailed: {
        return {...state, error: 'get', loading: false}
      }
      case actionTypes.createCompanySucceded: {
        return {...state, companiesList: [...state.companiesList, action.payload.data]}
      }
      case actionTypes.editCompanySucceded: {
        return {
          ...state,
          companiesList: [
            ...state.companiesList.filter((el: any) => el.id != action?.payload?.data?.id),
            action.payload.data,
          ],
        }
      }
      case actionTypes.deleteCompanySucceded: {
        return {
          ...state,
          companiesList: [
            ...state.companiesList.filter((el: any) => el.id != action?.payload?.data?.id),
          ],
        }
      }
      case actionTypes.getRolesSucceded: {
        const rolesList = action.payload?.data
        const role = {}
        return {...state, rolesList, role}
      }
      case actionTypes.getPermissionsSucceded: {
        const permissionsList = action.payload?.data
        return {...state, permissionsList}
      }
      case actionTypes.getRoleSucceded: {
        const role = action.payload?.data
        return {...state, role}
      }
      case actionTypes.createRoleSucceded: {
        return {...state, rolesList: [...state.rolesList, action.payload.data]}
      }
      case actionTypes.editRoleSucceded: {
        return {
          ...state,
          rolesList: [
            ...state.rolesList.filter((el: any) => el.id != action?.payload?.data?.id),
            action.payload.data,
          ],
        }
      }
      case actionTypes.deleteRoleSucceded: {
        return {
          ...state,
          rolesList: [...state.rolesList.filter((el: any) => el.id != action?.payload?.data?.id)],
        }
      }
      case actionTypes.getCountriesSucceded: {
        const countriesList = action.payload?.data
        return {
          ...state,
          countriesList,
          first_page: action.payload?.meta?.first_page,
          last_page: action.payload?.meta?.last_page,
          current_page: action.payload?.meta?.current_page,
        }
      }
      case actionTypes.getCountrySucceded: {
        const country = action.payload?.data
        return {...state, country}
      }
      case actionTypes.getCategoriesSucceded: {
        const categories = action.payload.data
        return {...state, categories}
      }
      case actionTypes.getMetaDataSucceded: {
        const metaData = action.payload.data
        return {...state, metaData}
      }
      case actionTypes.getSites: {
        return {...state, loading: true}
      }
      case actionTypes.getSitesSucceded: {
        const sites = action.payload
        return {...state, sites: sites, success: 'get', loading: false}
      }
      case actionTypes.getSitesFailed: {
        return {...state, error: 'get', loading: false}
      }
      default:
        return state
    }
  }
)

export const actions = {
  getCompanyUsersSucceded: (data: any) => ({
    type: actionTypes.getCompanyUsersSucceded,
    payload: data,
  }),
  getCompanyUsers: (filter?: any) => ({type: actionTypes.getCompanyUsers, payload: filter}),
  getCompanyUserSucceded: (data: any) => ({
    type: actionTypes.getCompanyUserSucceded,
    payload: data,
  }),
  getCompanyUser: (data: any) => ({type: actionTypes.getCompanyUser, payload: data}),
  editCompanyUser: (data: any) => ({type: actionTypes.editCompanyUser, payload: data}),
  editCompanyUserSucceded: (data: any) => ({
    type: actionTypes.editCompanyUserSucceded,
    payload: data,
  }),
  createRoleSucceded: (data: any) => ({type: actionTypes.createRoleSucceded, payload: data}),
  createRole: (data: any, history: any) => ({
    type: actionTypes.createRole,
    payload: data,
    history: history,
  }),
  editRole: (data: any, history: any) => ({
    type: actionTypes.editRole,
    payload: data,
    history: history,
  }),
  editRoleSucceded: (data: any) => ({type: actionTypes.editRoleSucceded, payload: data}),
  deleteRole: (data: any) => ({type: actionTypes.deleteRole, payload: data}),
  getRoleSucceded: (data: any) => ({type: actionTypes.getRoleSucceded, payload: data}),
  getRole: (data: any) => ({type: actionTypes.getRole, payload: data}),
  getRolesSucceded: (data: any) => ({type: actionTypes.getRolesSucceded, payload: data}),
  getRoles: () => ({type: actionTypes.getRoles}),
  getPermissionsSucceded: (data: any) => ({
    type: actionTypes.getPermissionsSucceded,
    payload: data,
  }),
  getPermissions: () => ({type: actionTypes.getPermissions}),
  editCompany: (data: any) => ({type: actionTypes.editCompany, payload: data}),
  editCompanySucceded: (data: any) => ({type: actionTypes.editCompanySucceded, payload: data}),
  deleteCompany: (data: any) => ({type: actionTypes.deleteCompany, payload: data}),
  getCompanySucceded: (data: any) => ({type: actionTypes.getCompanySucceded, payload: data}),
  getCompany: (data: any) => ({type: actionTypes.getCompany, payload: data}),
  getCompaniesFailed: () => ({type: actionTypes.getCompaniesFailed}),
  getCompaniesSucceded: (data: any) => ({type: actionTypes.getCompaniesSucceded, payload: data}),
  getCompanies: (filter?: any) => ({type: actionTypes.getCompanies, payload: filter}),
  getCountriesFailed: () => ({type: actionTypes.getCountriesFailed}),
  getCountriesSucceded: (data: any) => ({type: actionTypes.getCountriesSucceded, payload: data}),
  getCountries: (filter?: any) => ({type: actionTypes.getCountries, payload: filter}),
  getCategoriesSucceded: (data: any) => ({type: actionTypes.getCategoriesSucceded, payload: data}),
  getCategories: () => ({type: actionTypes.getCategories}),
  createCompanySucceded: (data: any) => ({type: actionTypes.createCompanySucceded, payload: data}),
  createCompany: (data: any, history: any) => ({
    type: actionTypes.createCompany,
    payload: data,
    history: history,
  }),
  getMetaDataSucceded: (data: any) => ({type: actionTypes.getMetaDataSucceded, payload: data}),
  getMetaData: () => ({type: actionTypes.getMetaData}),
  getSitesSucceded: (data: any) => ({type: actionTypes.getSitesSucceded, payload: data}),
  getSitesFailed: () => ({type: actionTypes.getSitesFailed}),
  getSites: (data: any) => ({type: actionTypes.getSites, payload: data}),
  store: () => ({type: 'def'}),
}

export function* saga() {
  yield takeEvery(actionTypes.getCompanyUsers, function* getCompanyUsersSaga(payload: any) {
    const {data} = yield getCompanyUsersApi(payload?.payload?.page, payload?.payload?.filter)
    yield put(actions.getCompanyUsersSucceded(data))
  })
  yield takeEvery(actionTypes.getCompanyUser, function* getCompanyUserSaga(data: any) {
    const user: any = ({} = yield getCompanyUserApi(data?.payload))
    yield put(actions.getCompanyUserSucceded(user))
  })
  yield takeEvery(actionTypes.editCompanyUser, function* editCompanyUserSaga(data: any) {
    const createdData: {} = yield editCompanyUser(data?.payload)
    yield put(actions.editCompanyUserSucceded(createdData))
  })
  yield takeEvery(actionTypes.getPermissions, function* getPermissionsSaga() {
    const {data} = yield getPermissionsApi()
    yield put(actions.getPermissionsSucceded(data))
  })
  yield takeEvery(actionTypes.getRoles, function* getRolesSaga() {
    const {data} = yield getRolesApi()
    yield put(actions.getRolesSucceded(data))
  })
  yield takeEvery(actionTypes.getRole, function* getRoleSaga(data: any) {
    const role: {} = yield getRoleApi(data?.payload?.id)
    yield put(actions.getRoleSucceded(role))
  })

  yield takeEvery(actionTypes.editRole, function* editRoleSaga(data: any) {
    const createdRole: {} = yield editRole(data?.payload)
    yield put(actions.editRoleSucceded(createdRole))
    setTimeout(() => {
      data?.history?.push('/crafted/pages/company/role/list')
    })
  })
  yield takeEvery(actionTypes.createRole, function* createRoleSaga(data: any) {
    const createdRole: {} = yield createRole(data?.payload)
    yield put(actions.createRoleSucceded(createdRole))
    setTimeout(() => {
      data?.history?.push('/crafted/pages/company/role/list')
    })
  })
  yield takeEvery(actionTypes.deleteRole, function* deleteRoleSaga(data: any) {
    yield deleteRole(data?.payload?.id)
    yield put(actions.getRoles())
  })
  yield takeEvery(actionTypes.getCompanies, function* getCompaniesSaga(payload: any) {
    const {data} = yield getCompaniesApi(payload?.payload?.page, payload?.payload?.filter)
    yield put(actions.getCompaniesSucceded(data))
  })
  yield takeEvery(actionTypes.getCompany, function* getCompanySaga(data: any) {
    try {
      const role: {} = yield getCompanyApi(data?.payload?.id)
      yield put(actions.getCompanySucceded(role))
    } catch (err) {
      yield put(actions.getCompaniesFailed())
    }
  })

  yield takeEvery(actionTypes.editCompany, function* editCompanySaga(data: any) {
    const createdCompany: {} = yield editCompanyApi(data?.payload)
    yield put(actions.editCompanySucceded(createdCompany))
  })
  yield takeEvery(actionTypes.deleteCompany, function* deleteCompanySaga(data: any) {
    yield deleteCompanyApi(data?.payload?.id)
    yield put(actions.getCompanies())
  })
  yield takeEvery(actionTypes.getCountries, function* getCountriesSaga(payload: any) {
    try {
      const {data} = yield getCountriesApi(payload?.payload?.page, payload?.payload?.filter)
      yield put(actions.getCountriesSucceded(data))
    } catch (error) {
      yield put(actions.getCountriesFailed())
    }
  })

  yield takeEvery(actionTypes.createCompany, function* createCompanySaga(data: any) {
    const createdCompany: {} = yield register(data?.payload)
    yield put(actions.createCompanySucceded(createdCompany))
    setTimeout(() => {
      data?.history?.push('/crafted/pages/company/company/list')
    })
  })
  yield takeEvery(actionTypes.getCategories, function* getCategoriesSaga(id: any) {
    //@ts-ignore
    const {data} = yield getCategories()
    yield put(actions.getCategoriesSucceded(data))
  })
  yield takeEvery(actionTypes.getMetaData, function* getMetaDataSaga(id: any) {
    const {data} = yield getMetaData()
    yield put(actions.getMetaDataSucceded(data))
  })
  yield takeEvery(actionTypes.getSites, function* getSitesSaga(filter: any) {
    try {
      const {
        data: {data},
      } = yield getSites(filter?.payload?.field, filter?.payload?.operand, filter?.payload?.value)
      yield put(actions.getSitesSucceded(data))
    } catch (e) {
      yield put(actions.getSitesFailed())
    }
  })
}
