import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import * as redux from '../../redux/CompanyRedux'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {RootState} from '../../../../../setup'
export function User() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [user, setUser] = useState({id: '', first_name: '', last_name: '', status: ''})
  const users: any = useSelector<RootState>(({company}) => company.usersList)
  const last_page: any = useSelector<RootState>(({company}) => company.last_page)
  const [page, setPage] = useState<any>(1)
  useEffect(() => {
    dispatch(redux.actions.getCompanyUsers({page: page}))
  }, [page])
  const updateUser = (user: any) => {
    setUser(user)
  }
  const goToAccount = (user: any) => {
    history.push('/crafted/pages/company/user/'+user.id+'/overview')
  }
  const confirmEdit = (user: any) => {
    dispatch(redux.actions.editCompanyUser(user))
  }
  const cancelEdit = () => {
    dispatch(redux.actions.getCompanyUsers({page: page}))
  }
  return (
    users && (
      <>
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='border-0'>
                <th className='p-0 w-50px'>Image</th>
                <th className='p-0 min-w-100px'>Name</th>
                <th className='p-0 min-w-100px'>Company</th>
                <th className='p-0 min-w-60px'>Phone</th>
                <th className='p-0 min-w-60px'>Status</th>
                <th className='p-0 min-w-60px'>Last Login</th>
                <th className='p-0 min-w-60px'>Joined At</th>
                <th className='p-0 min-w-80px'>Action</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {users.map((user: any, i: any) => {
                return (
                  <tr key={i}>
                    <td onClick={()=>{goToAccount(user)}}>
                      <div className='symbol symbol-45px me-2'>
                        <span className='symbol-label'>
                          <img src={user.image_url} className='h-50 align-self-center' alt='' />
                        </span>
                      </div>
                    </td>
                    <td onClick={()=>{goToAccount(user)}}>
                      <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                        {user.first_name + ' ' + user.last_name}
                      </a>
                      <span className='text-muted fw-bold d-block'>{user.email || '-'}</span>
                    </td>
                    <td onClick={()=>{goToAccount(user)}}>
                      <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                        {user.company?.name}
                      </a>
                    </td>
                    <td onClick={()=>{goToAccount(user)}} className='text-start text-muted fw-bold'>{user.phone || '-'}</td>
                    <td className='text-start'>
                      {user.status == 'active' && (
                        <span className='badge badge-light-success'>{user.status}</span>
                      )}
                      {user.status == 'pending' && (
                        <span className='badge badge-light-warning'>{user.status}</span>
                      )}
                      {user.status == 'blocked' && (
                        <span className='badge badge-light-danger'>{user.status}</span>
                      )}
                    </td>
                    <td onClick={()=>{goToAccount(user)}} className='text-start text-muted fw-bold'>
                      {(user.last_login_at &&
                        moment(user.last_login_at).format('DD-MM-YYYY HH:mm')) ||
                        '-'}
                    </td>
                    <td className='text-start text-muted fw-bold'>
                      {moment(user.created_at).format('DD-MM-YYYY HH:mm')}
                    </td>
                    <td className='text-start'>
                      <div className='row mb-0'>
                        {user.status == 'active' && (
                          <label className='col-lg-4 col-form-label fw-bold fs-6'>Block</label>
                        )}
                        {user.status != 'active' && (
                          <label className='col-lg-4 col-form-label fw-bold fs-6'>Activate</label>
                        )}

                        <div className='col-lg-8 d-flex align-items-center'>
                          <div
                            className='form-check form-check-solid form-switch fv-row'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_1'
                          >
                            <input
                              className='form-check-input w-45px h-30px'
                              type='checkbox'
                              id='allowmarketing'
                              checked={user.status == 'active'}
                              onChange={() => {
                                if(user.status == 'active')
                                user.status = 'blocked'
                                else
                                user.status = 'active'
                                updateUser(user)
                              }}
                            />
                            <label className='form-check-label'></label>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          <div className='d-flex flex-stack flex-wrap pt-10' style={{float: 'right'}}>
            <ul className='pagination'>
              {last_page &&
                Array.from(Array(last_page), (_, i) => i + 1).map((number: any) => {
                  return (
                    <li key = {number}  className={`page-item ${number == page ? 'active' : ''}`}>
                      <a onClick={() => setPage(number)} className='page-link'>
                        {number}
                      </a>
                    </li>
                  )
                })}
            </ul>
          </div>
          <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title'>Update User</h5>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon svg-icon-2x'
                    />
                  </div>
                </div>
                <div className='modal-body'>
                  {user.status != 'active' && (
                    <p>
                      Are you sure you want to activate "{user?.first_name + ' ' + user?.last_name}"
                      ?
                    </p>
                  )}
                  {user.status == 'active' && (
                    <p>
                      Are you sure you want to block "{user?.first_name + ' ' + user?.last_name}" ?
                    </p>
                  )}
                </div>
                <div className='modal-footer'>
                  <button type='button' className='btn btn-light' data-bs-dismiss='modal'
                    onClick={(e) => {
                      cancelEdit()
                    }}>
                    Close
                  </button>
                  <button
                    type='button'
                    data-bs-dismiss='modal'
                    onClick={(e) => {
                      confirmEdit(user)
                    }}
                    className='btn btn-primary'
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* end::Table */}
        </div>
      </>
    )
  )
}
