import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {Formik} from 'formik'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import * as redux from '../../redux/CompanyRedux'
import {RootState} from '../../../../../setup'

// Utility function to group permissions by entity (prefix)
const groupPermissionsByEntity = (permissions: any) => {
  const groupedPermissions: any = {}

  permissions.forEach((permission: any) => {
    const [entity, action] = permission.name.split('.') // Split by "."

    if (!groupedPermissions[entity]) {
      groupedPermissions[entity] = []
    }

    groupedPermissions[entity].push(action) // Collect actions for the entity
  })

  return groupedPermissions
}

export function CreateRole(props: any) {
  const dispatch = useDispatch()
  const history = useHistory()
  const [permissionsTab, setPermissionsTab]: any = useState([])
  const permissions: any = useSelector<RootState>(({company}) => company.permissionsList)
  const [groupedPermissions, setGroupedPermissions] = useState<any>({})

  useEffect(() => {
    dispatch(redux.actions.getPermissions())
  }, [])
  useEffect(() => {
    if (permissions) {
      setGroupedPermissions(groupPermissionsByEntity(permissions))
    }
  }, [permissions])
  const handleSubmit = (values: any, e: any) => {
    e.preventDefault()
    values.permissions = permissionsTab.length > 0 ? permissionsTab.map((el: any) => el.id) : []
    dispatch(redux.actions.createRole(values, history))
  }
  return (
    <>
      <Formik
        onSubmit={(values, {setSubmitting}) => {
          setSubmitting(true)
        }}
        initialValues={{
          name: '',
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Required'),
        })}
      >
        {({
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleReset,
          setSubmitting,
          setFieldValue,
          resetForm,
        }) => (
          <form className='form'>
            <div className='w-100'>
              {/*begin::Heading */}
              {/*begin::Heading */}

              {/*begin::Form Group */}
              <div className='fv-row mb-12 col-3'>
                <label className='fs-6 fw-bolder text-dark form-label'>Role Name</label>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  name='name'
                  placeholder=''
                  value={values.name}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className='bg-white px-5 card-body border-top px-9 pt-3 pb-4 mb-5 rounded-2'>
              <div className='table-responsive'>
                <table className='table table-row-dashed border-gray-300 align-middle gy-6'>
                  <tbody className='fs-6 fw-bold'>
                    <tr style={{display: 'grid'}}>
                      <td className='min-w-250px fs-4 fw-bolder'>Permissions</td>
                      <td className='w-125px'>
                        {Object.keys(groupedPermissions).map((entity, i) => (
                          <div key={i} className='my-5'>
                            <strong>{entity.charAt(0).toUpperCase() + entity.slice(1)}:</strong>
                            <div className='ml-4'>
                              {groupedPermissions[entity].map((action: string, j: number) => (
                                <div key={j} className='form-check form-check-solid my-3'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    value=''
                                    checked={permissionsTab.find(
                                      (el: any) => el.name === `${entity}.${action}`
                                    )}
                                    onChange={(event) => {
                                      const permission = permissions.find(
                                        (el: any) => el.name === `${entity}.${action}`
                                      )
                                      if (event.target.checked) {
                                        setPermissionsTab([...permissionsTab, permission])
                                      } else {
                                        setPermissionsTab(
                                          permissionsTab.filter(
                                            (el: any) => el.id !== permission.id
                                          )
                                        )
                                      }
                                    }}
                                  />
                                  <label className='form-check-label ps-2'>{action}</label>
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <button
              className='btn btn-primary'
              style={{backgroundColor: '#87e8c5'}}
              onClick={(e) => {
                setSubmitting(true)
                handleSubmit(values, e)
              }}
              type='submit'
            >
              Submit
            </button>
          </form>
        )}
      </Formik>
    </>
  )
}
