import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
export const UserURL = `${API_URL}/company_users/`
export const RoleURL = `${API_URL}/roles/`
export const PermissionURL = `${API_URL}/permissions/`
export const CompanyURL = `${API_URL}/companies/`
export const GET_COUNTRIES = `${API_URL}/countries`
export const GET_CITIES = `${API_URL}/cities/`
export const UPLOAD_IMAGE = `${API_URL}/upload`
export const GET_CATEGORIES = `${API_URL}/categories`
export const REGISTER_URL = `${API_URL}/companies/admin/store`

export function getCountries() {
  return axios.get<any>(GET_COUNTRIES)
}

export function getCountriesApi(page: any, filter?: any) {
  return axios.get(`${API_URL}/countries/paginate?page=${page}&perPage=20`)
}

export function getCities() {
  return axios.get<any>(GET_CITIES)
}

export const GET_METADATA = `${API_URL}/metadata`
export const GET_SITES = `${API_URL}/sites`
export function getCompanyUsersApi(page: any, filter?: any) {
  return axios.get(UserURL + `paginate?page=${page}&perPage=100`)
}
export function getCompanyUserApi(data: any) {
  return axios.get<any>(UserURL + data.id)
}
export function editCompanyUser(data: any) {
  return axios.patch<any>(UserURL + data.id, data)
}

export function getRolesApi() {
  return axios.get(RoleURL)
}
export function getCompaniesApi(page: any, filter?: any) {
  if (!filter) return axios.get(CompanyURL + `paginate?page=${page}&perPage=20`)
  else return axios.get(CompanyURL + `paginate?page=${page}&perPage=20&filter=${filter}`)
}
export function getCompanyApi(id: any) {
  return axios.get(CompanyURL + id)
}
export function editCompanyApi(data: any) {
  return axios.patch<any>(CompanyURL + data.id, data)
}
export function deleteCompanyApi(id: any) {
  return axios.delete<any>(CompanyURL + id)
}
export function getPermissionsApi() {
  return axios.get(PermissionURL)
}
export function getRoleApi(id: any) {
  return axios.get<any>(RoleURL + id)
}
export function editRole(data: any) {
  return axios.patch<any>(RoleURL + data.id, data)
}
export function createRole(data: any) {
  return axios.post<any>(RoleURL, data)
}
export function deleteRole(id: any) {
  return axios.delete<any>(RoleURL + id)
}

export function uploadImage(image: File) {
  let formData = new FormData()
  formData.append('file', image)
  return axios.post<any>(UPLOAD_IMAGE, formData)
}

export function getCategories() {
  return axios.get<any>(
    GET_CATEGORIES + `?filter={"status":{"orWhere": true,"op":"=","value":"active"}}`
  )
}

export function register(data: any) {
  return axios.post<any>(REGISTER_URL, data)
}

// export function register(
//   image_url: string,
//   name: string,
//   domain_of_activity: string,
//   email: string,
//   phone: string,
//   website: string,
//   country_id: number,
//   city_id: number,
//   registration_number: string,
//   description: string
// ) {
//   return axios.post(REGISTER_URL, {
//     image_url,
//     name,
//     domain_of_activity,
//     email,
//     phone,
//     website,
//     country_id,
//     city_id,
//     registration_number,
//     description,
//   })
// }
export function getMetaData() {
  return axios.get<any>(
    GET_METADATA +
      '?filter={"status":{"op":"=","value":"active"}, "type":{"op":"!=","value":"company"}}'
  )
}

// export function getCategories(company_id: any) {
//   return axios.get<any>(
//     GET_CATEGORIES +
//       `?filter={"status":{"orWhere": true,"op":"=","value":"active"}}&nestedfilter={"user":{"filters":[{"field":"company_id","op":"=","value":"${company_id}"}]}}`
//   )
// }
export function getSites(filter: string, operand: string, value: any) {
  return axios.get<any>(GET_SITES + `?filter={"${filter}":{"op":"${operand}","value":"${value}"}}`)
}
