import React, {useEffect} from 'react'
import * as Yup from 'yup'
import {Formik} from 'formik'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import * as redux from '../../redux/CategoryRedux'
import {RootState} from '../../../../../setup'
export function CreateDepartment(props: any) {
  const dispatch = useDispatch()
  const history = useHistory()
  const domains: any = useSelector<RootState>(({category}) => category.domainsList)

  useEffect(() => {
    dispatch(redux.actions.getDomains())
  }, [])
  const handleSubmit = (values: any, e: any) => {
    e.preventDefault()
    dispatch(redux.actions.createCategory(values, history))
  }
  return (
    <>
      <Formik
        onSubmit={(values, {setSubmitting}) => {
          setSubmitting(true)
        }}
        initialValues={{
          name: '',
          name_ar: '',
          parent_id: '',
          type: 'department',
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Required'),
        })}
      >
        {({
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleReset,
          setSubmitting,
          setFieldValue,
          resetForm,
        }) => (
          <form className='form'>
            <div className='w-100'>
              {/*begin::Heading */}
              {/*begin::Heading */}

              {/*begin::Form Group */}
              <div className='fv-row mb-12 col-3'>
                <label className='fs-6 fw-bolder text-dark form-label'>Department Name</label>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  name='name'
                  placeholder=''
                  value={values.name}
                  onChange={handleChange}
                />
              </div>
              <div className='fv-row mb-12 col-3'>
                <label className='fs-6 fw-bolder text-dark form-label'>Department Arabic Name</label>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  name='name_ar'
                  value={values.name_ar}
                  onChange={handleChange}
                />
              </div>
              {
                    domains?.length > 0 && domains[0].name && (
                      <div className='fv-row mb-12 col-3'>
                        <label className='fs-6 fw-bolder text-dark form-label'>Domain Name</label>
                        <select
                          className='form-select'
                          aria-label='Select example'
                          value={values.parent_id}
                          name='parent_id'
                          onChange={handleChange}
                        >
                          <option></option>
                          {domains.map((domain: any, i:number)=>
                              {
                                return <option key = {i} value={domain.id}>{domain.name}</option>
                              })
                          }
                        </select>
                      </div>
                      
                    )
              }
            </div>

            <button
              className='btn btn-primary'
              style={{backgroundColor: '#87e8c5'}}
              onClick={(e) => {
                setSubmitting(true)
                handleSubmit(values, e)
              }}
              type='submit'
            >
              Submit
            </button>
          </form>
        )}
      </Formik>
    </>
  )
}
