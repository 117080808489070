/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useParams} from 'react-router-dom'
import {RootState} from '../../../../../../setup'
import {KTSVG, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {DataTable} from './TablesWidget5'
import * as redux from '../../../redux/CompanyRedux'
import {Modal, Button} from 'react-bootstrap-v5'

export function CompanyOverview() {
  const dispatch = useDispatch()
  const params: any = useParams()
  const [open, setOpen] = useState(false)
  const [companyToUpdate, setCompanyToUpdate] = useState({id: null, status: ''})
  const company: any = useSelector<RootState>(({company}) => company.company)
  useEffect(() => {
    dispatch(redux.actions.getCompany({id: params.id}))
    if (company.id) setCompanyToUpdate(company)
  }, [])
  const confirmEdit = (company: any) => {
    dispatch(redux.actions.editCompany(company))
    setOpen(false)
  }
  const cancelEdit = () => {
    setOpen(false)
    dispatch(redux.actions.getCompany({id: params.id}))
  }
  return (
    <>
      {company?.id && (
        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
          <div className='card-header cursor-pointer'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Company Details</h3>
            </div>
          </div>

          <div className='card-body p-9'>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Logo</label>
              <img
                alt='img'
                className='rounded w-200px'
                src={company ? company.image_url : '/media/logos/blank.png'}
              />
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Name</label>

              <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{company.name}</span>
              </div>
            </div>

            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Contact Email</label>

              <div className='col-lg-8 d-flex align-items-center'>
                <span className='fw-bolder fs-6 me-2'>{company.email || '-'}</span>

                {
                  //<span className='badge badge-success'>Verified</span>
                }
              </div>
            </div>

            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Phone</label>

              <div className='col-lg-8 d-flex align-items-center'>
                <span className='fw-bolder fs-6 me-2'>{company.phone || '-'}</span>

                {
                  //<span className='badge badge-success'>Verified</span>
                }
              </div>
            </div>

            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Status</label>

              <div className='col-lg-8 d-flex align-items-center'>
                <span className='fw-bolder fs-6 me-2'>{company.status || '-'}</span>

                {
                  //<span className='badge badge-success'>Verified</span>
                }
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Website</label>

              <div className='col-lg-8 d-flex align-items-center'>
                <a href={company.website || ''} target='_blank' className='fw-bolder fs-6 me-2'>
                  {company.website || '-'}
                </a>

                {
                  //<span className='badge badge-success'>Verified</span>
                }
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Registration File</label>

              <div className='col-lg-8 d-flex align-items-center'>
                {company?.registration_file ? (
                  <a
                    href={company?.registration_file}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='fw-bold fs-6'
                  >
                    {
                      company?.registration_file.split('/')[
                        company?.registration_file.split('/').length - 1
                      ]
                    }
                  </a>
                ) : (
                  <span className='fw-bold fs-6'>Not Provided</span>
                )}
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Size</label>

              <div className='col-lg-8 d-flex align-items-center'>
                <span className='fw-bolder fs-6 me-2'>{company.size || '-'}</span>

                {
                  //<span className='badge badge-success'>Verified</span>
                }
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Offre number</label>

              <div className='col-lg-8 d-flex align-items-center'>
                <span className='fw-bolder fs-6 me-2'>{company.nb_offers || '-'}</span>

                {
                  //<span className='badge badge-success'>Verified</span>
                }
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Country</label>

              <div className='col-lg-8 d-flex align-items-center'>
                <span className='fw-bolder fs-6 me-2'>{company.country?.name || '-'}</span>

                {
                  //<span className='badge badge-success'>Verified</span>
                }
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>City</label>

              <div className='col-lg-8 d-flex align-items-center'>
                <span className='fw-bolder fs-6 me-2'>{company.city?.name || '-'}</span>

                {
                  //<span className='badge badge-success'>Verified</span>
                }
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-muted'>Phone</label>

              <div className='col-lg-8 d-flex align-items-center'>
                <span className='fw-bolder fs-6 me-2'>{company.phone || '-'}</span>

                {
                  //<span className='badge badge-success'>Verified</span>
                }
              </div>
            </div>

            <div className='row mb-7'>
              <div className='col-lg-2 d-flex align-items-center'>
                <select
                  className='form-select form-select-solid'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  value=''
                  onChange={(event) => {
                    if (event.target.value) {
                      let temp = company
                      temp.status = event.target.value
                      setCompanyToUpdate(temp)
                      setOpen(true)
                    }
                  }}
                >
                  <option disabled={true} value='' selected={true}>
                    Select action
                  </option>
                  {company.status != 'active' && (
                    <option value='active' selected={false}>
                      Activate
                    </option>
                  )}
                  {company.status != 'pending' && (
                    <option value='pending' selected={false}>
                      Put on Hold
                    </option>
                  )}
                  {company.status != 'blocked' && (
                    <option value='blocked' selected={false}>
                      Block
                    </option>
                  )}
                </select>
              </div>
            </div>
          </div>
          <Modal show={open} onHide={cancelEdit}>
            <Modal.Header closeButton>
              <Modal.Title>Update Company</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {company.status == 'active' && (
                <p>Are you sure you want to activate "{company?.name}" ?</p>
              )}
              {company.status == 'pending' && (
                <p>Are you sure you want to put on hold "{company?.name}" ?</p>
              )}
              {company.status == 'blocked' && (
                <p>Are you sure you want to block "{company?.name}" ?</p>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={cancelEdit}>
                Close
              </Button>
              <Button
                variant='primary'
                onClick={(e) => {
                  confirmEdit(companyToUpdate)
                }}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  )
}
