import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import * as redux from '../../redux/DataRedux'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {RootState} from '../../../../../setup'
export function Site() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [cityToDelete, setCityToDelete] = useState({id: null, name: '', name_ar: ''})
  const sites: any = useSelector<RootState>(({data}) => data.sitesList)
  const [filter, setFilter] = useState<any>({})
  const last_page: any = useSelector<RootState>(({data}) => data.last_page)
  const [page, setPage] = useState<any>(1)
  useEffect(() => {
    dispatch(redux.actions.getSites({page: page}))
  }, [page])
  const deleteCity = (data: any) => {
    setCityToDelete(data)
  }
  const editCity = (data: any) => {
    // history.push('/crafted/pages/data/city/' + data.id)
  }
  const confirmDeleteCity = (data: any) => {
    // dispatch(redux.actions.deleteCity(data))
  }
  return (
    sites && (
      <>
        <div className='table-responsive'>
          {/* begin::Table */}
          <a
            href='/crafted/pages/data/sites/create'
            className='btn btn-sm btn-primary'
            id='kt_toolbar_primary_button'
            style={{float: 'right', backgroundColor: '#87e8c5'}}
          >
            Create Site
          </a>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-150px'>Name</th>
                <th className='min-w-140px'>City</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {sites.map((city: any, i: any) => {
                return (
                  <tr key={i}>
                    <td>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input widget-9-check'
                          type='checkbox'
                          value='1'
                        />
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                            {city.name}
                          </a>
                        </div>
                      </div>
                    </td>

                    <td className='text-end'>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2'>
                          <span className='text-muted me-2 fs-7 fw-bold'>{city?.city?.name}</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <a
                          // onClick={(e) => {
                          //   editCity(city)
                          // }}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <KTSVG
                            path='/media/icons/duotune/art/art005.svg'
                            className='svg-icon-3'
                          />
                        </a>
                        <a
                          // onClick={(e) => {
                          //   deleteCity(city)
                          // }}

                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon-3'
                          />
                        </a>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          <div className='d-flex flex-stack flex-wrap pt-10' style={{float: 'right'}}>
            <ul className='pagination'>
              {last_page &&
                Array.from(Array(last_page), (_, i) => i + 1).map((number: any) => {
                  return (
                    <li key={number} className={`page-item ${number == page ? 'active' : ''}`}>
                      <a onClick={() => setPage(number)} className='page-link'>
                        {number}
                      </a>
                    </li>
                  )
                })}
            </ul>
          </div>

          {/* end::Table */}
        </div>
      </>
    )
  )
}
