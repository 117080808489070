import {Formik} from 'formik'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {RootState} from '../../../../../setup'
import * as redux from '../../redux/DataRedux'
import {createUserValidationSchema} from './validation/create-user-validation'
export function CreateUser(props: any) {
  const sites: any = useSelector<RootState>(({data}) => data.sitesList)
  const user: any = useSelector<RootState>(({auth}) => auth.user)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(redux.actions.getSites({page: 1}))
  }, [])

  const handleSubmit = (values: any, e: any) => {
    e.preventDefault()
    console.log('values+++++++', values)
    values.admin_id = user?.user?.id
    dispatch(redux.actions.createIAMUser({...values}, history))
  }

  console.log('user++++++++++++++', user?.user?.id)
  return (
    <>
      <Formik
        onSubmit={(values, {setSubmitting}) => {
          setSubmitting(true)
        }}
        initialValues={{
          first_name: '',
          last_name: '',
          education_level: '',
          social_situation: '',
          email: '',
          password: '',
          permissions: '',
          admin_sites_id: '',
        }}
        validationSchema={createUserValidationSchema}
      >
        {({
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleReset,
          setSubmitting,
          setFieldValue,
          resetForm,
        }) => (
          <form className='form'>
            <div className='w-100'>
              {/* First Name */}
              <div className='fv-row mb-12 col-3'>
                <label className='fs-6 fw-bolder text-dark form-label'>First Name</label>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  name='first_name'
                  placeholder='Enter your first name'
                  value={values.first_name}
                  onChange={handleChange}
                />
                {errors.first_name && touched.first_name && (
                  <div className='error'>{errors.first_name}</div>
                )}
              </div>

              {/* Last Name */}
              <div className='fv-row mb-12 col-3'>
                <label className='fs-6 fw-bolder text-dark form-label'>Last Name</label>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  name='last_name'
                  placeholder='Enter your last name'
                  value={values.last_name}
                  onChange={handleChange}
                />
                {errors.last_name && touched.last_name && (
                  <div className='error'>{errors.last_name}</div>
                )}
              </div>

              {/* Education Level */}
              <div className='fv-row mb-12 col-3'>
                <label className='fs-6 fw-bolder text-dark form-label'>Education Level</label>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  name='education_level'
                  placeholder='Enter your education level'
                  value={values.education_level}
                  onChange={handleChange}
                />
                {errors.education_level && touched.education_level && (
                  <div className='error'>{errors.education_level}</div>
                )}
              </div>

              {/* Social Situation */}
              <div className='fv-row mb-12 col-3'>
                <label className='fs-6 fw-bolder text-dark form-label'>Social Situation</label>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  name='social_situation'
                  placeholder='Enter your social situation'
                  value={values.social_situation}
                  onChange={handleChange}
                />
                {errors.social_situation && touched.social_situation && (
                  <div className='error'>{errors.social_situation}</div>
                )}
              </div>

              {/* Email */}
              <div className='fv-row mb-12 col-3'>
                <label className='fs-6 fw-bolder text-dark form-label'>Email</label>
                <input
                  type='email'
                  className='form-control form-control-lg'
                  name='email'
                  placeholder='Enter your email'
                  value={values.email}
                  onChange={handleChange}
                />
                {errors.email && touched.email && <div className='error'>{errors.email}</div>}
              </div>

              {/* Password */}
              <div className='fv-row mb-12 col-3'>
                <label className='fs-6 fw-bolder text-dark form-label'>Password</label>
                <input
                  type='password'
                  className='form-control form-control-lg'
                  name='password'
                  placeholder='Enter your password'
                  value={values.password}
                  onChange={handleChange}
                />
                {errors.password && touched.password && (
                  <div className='error'>{errors.password}</div>
                )}
              </div>

              {/* City Selection (Optional) */}
              {sites?.length > 0 && sites[0].name && (
                <div className='fv-row mb-12 col-3'>
                  <label className='fs-6 fw-bolder text-dark form-label'>Sites</label>
                  <select
                    className='form-select'
                    aria-label='Select example'
                    value={values.admin_sites_id}
                    name='admin_sites_id'
                    onChange={handleChange}
                  >
                    <option></option>
                    {sites.map((p: any, i: number) => (
                      <option key={i} value={p.id}>
                        {p.name}
                      </option>
                    ))}
                  </select>
                  {errors.admin_sites_id && touched.admin_sites_id && (
                    <div className='error'>{errors.admin_sites_id}</div>
                  )}
                </div>
              )}
            </div>

            <button
              className='btn btn-primary'
              onClick={(e) => {
                setSubmitting(true)
                handleSubmit(values, e)
              }}
              style={{backgroundColor: '#87e8c5'}}
              type='submit'
            >
              Submit
            </button>
          </form>
        )}
      </Formik>
    </>
  )
}
