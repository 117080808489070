import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import * as redux from '../../redux/DataRedux'
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {RootState} from '../../../../../setup'
export function Country() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [dataToDelete, setDataToDelete] = useState({id: null, name: '', name_ar: ''})
  const [filter, setFilter] = useState<any>({})
  const last_page: any = useSelector<RootState>(({data}) => data.last_page)
  const [page, setPage] = useState<any>(1)
  const countries: any = useSelector<RootState>(({data}) => data.countriesList)
  useEffect(() => {
    dispatch(redux.actions.getCountries({page: page}))
  }, [page])
  const deleteData = (data: any) => {
    setDataToDelete(data)
  }
  const editData = (data: any) => {
    history.push('/crafted/pages/data/country/' + data.id)
  }
  const confirmDeleteData = (data: any) => {
    dispatch(redux.actions.deleteCountry(data))
  }
  return (
    countries && (
      <>
        <div className='table-responsive'>
          {/* begin::Table */}
          <div className='bg-white rounded-3 p-5'>
            <a
              href='/crafted/pages/data/country/create'
              className='btn btn-sm btn-primary'
              id='kt_toolbar_primary_button'
              style={{float: 'right', backgroundColor: '#87e8c5'}}
            >
              Create
            </a>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className='w-25px'>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='1'
                        data-kt-check='true'
                        data-kt-check-target='.widget-9-check'
                      />
                    </div>
                  </th>
                  <th className='min-w-150px'>Name</th>
                  <th className='min-w-150px'>Arabic Name</th>
                  <th className='min-w-100px text-end'>Actions</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {countries.map((country: any, i: any) => {
                  return (
                    <tr key={i}>
                      <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input widget-9-check'
                            type='checkbox'
                            value='1'
                          />
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                              {country.name}
                            </a>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                              {country.name_ar}
                            </a>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <a
                            onClick={(e) => {
                              editData(country)
                            }}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <KTSVG
                              path='/media/icons/duotune/art/art005.svg'
                              className='svg-icon-3'
                            />
                          </a>
                          <a
                            onClick={(e) => {
                              deleteData(country)
                            }}
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_1'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen027.svg'
                              className='svg-icon-3'
                            />
                          </a>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
              {/* end::Table body */}
            </table>
            <div className='d-flex flex-stack flex-wrap pt-10' style={{float: 'right'}}>
              <ul className='pagination'>
                {last_page &&
                  Array.from(Array(last_page), (_, i) => i + 1).map((number: any) => {
                    return (
                      <li key={number} className={`page-item ${number == page ? 'active' : ''}`}>
                        <a onClick={() => setPage(number)} className='page-link'>
                          {number}
                        </a>
                      </li>
                    )
                  })}
              </ul>
            </div>
          </div>
          <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title'>Delete Country</h5>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon svg-icon-2x'
                    />
                  </div>
                </div>
                <div className='modal-body'>
                  <p>Are you sure you want to delete "{dataToDelete?.name}"</p>
                </div>
                <div className='modal-footer'>
                  <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                    Close
                  </button>
                  <button
                    type='button'
                    data-bs-dismiss='modal'
                    onClick={(e) => {
                      confirmDeleteData(dataToDelete)
                    }}
                    className='btn btn-primary'
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* end::Table */}
        </div>
      </>
    )
  )
}
