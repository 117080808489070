import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeEvery} from 'redux-saga/effects'
import {
  createCity,
  createCountry,
  createData,
  createIAMUser,
  createSite,
  deleteCity,
  deleteCountry,
  deleteData,
  editCity,
  editCountry,
  editData,
  getBenefitsApi,
  getCitiesApi,
  getCity,
  getCompaniesDataApi,
  getContractTypesApi,
  getCountriesApi,
  getCountry,
  getData,
  getDiplomesApi,
  getIAMUserApi,
  getIAMUsersApi,
  getJobTitlesApi,
  getSitesApi,
  getSkillsApi,
  getSpecialitiesApi,
  getUniversitiesApi,
  getWorkPostesApi,
  uploadDataApi,
} from './service'
export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  getSkills: '[getSkills] Action',
  getSkillsSucceded: '[getSkillsSucceded] Action',
  getSkillsFailed: '[getSkillsFailed] Action',
  getBenefits: '[getBenefits] Action',
  getBenefitsSucceded: '[getBenefitsSucceded] Action',
  getBenefitsFailed: '[getBenefitsFailed] Action',
  getCities: '[getCities] Action',
  getCitiesSucceded: '[getCitiesSucceded] Action',
  getCitiesFailed: '[getCitiesFailed] Action',
  createCity: '[createCity] Action',
  createCitySucceded: '[createCitySucceded] Action',
  createCityFailed: '[createCityFailed] Action',
  editCity: '[editCity] Action',
  editCitySucceded: '[editCityFailed] Action',
  editCityFailed: '[editCityFailed] Action',
  getCity: '[getCity] Action',
  getCitySucceded: '[getCitySucceded] Action',
  getCityFailed: '[getCityFailed] Action',
  deleteCity: '[deleteCity] Action',
  deleteCitySucceded: '[deleteCitySucceded] Action',
  deleteCityFailed: '[deleteCityFailed] Action',
  getCountries: '[getCountries] Action',
  getCountriesSucceded: '[getCountriesSucceded] Action',
  getCountriesFailed: '[getCountriesFailed] Action',
  createCountry: '[createCountry] Action',
  createCountrySucceded: '[createCountrySucceded] Action',
  createCountryFailed: '[createCountryFailed] Action',
  editCountry: '[editCountry] Action',
  editCountrySucceded: '[editCountryFailed] Action',
  editCountryFailed: '[editCountryFailed] Action',
  getCountry: '[getCountry] Action',
  getCountrySucceded: '[getCountrySucceded] Action',
  getCountryFailed: '[getCountryFailed] Action',
  deleteCountry: '[deleteCountry] Action',
  deleteCountrySucceded: '[deleteCountrySucceded] Action',
  deleteCountryFailed: '[deleteCountryFailed] Action',
  getUniversities: '[getUniversities] Action',
  getUniversitiesSucceded: '[getUniversitiesSucceded] Action',
  getUniversitiesFailed: '[getUniversitiesFailed] Action',
  getCompaniesData: '[getCompaniesData] Action',
  getCompaniesDataSucceded: '[getCompaniesDataSucceded] Action',
  getCompaniesDataFailed: '[getCompaniesDataFailed] Action',
  getSpecialities: '[getSpecialities] Action',
  getSpecialitiesSucceded: '[getSpecialitiesSucceded] Action',
  getSpecialitiesFailed: '[getSpecialitiesFailed] Action',
  getDiplomes: '[getDiplomes] Action',
  getDiplomesSucceded: '[getDiplomesSucceded] Action',
  getDiplomesFailed: '[getDiplomesFailed] Action',
  getWorkPostes: '[getWorkPostes] Action',
  getWorkPostesSucceded: '[getWorkPostesSucceded] Action',
  getWorkPostesFailed: '[getWorkPostesFailed] Action',
  getData: '[getData] Action',
  getDataSucceded: '[getDatasucceded] Action',
  getDataFailed: '[getDataFailed] Action',
  createData: '[createData] Action',
  uploadData: '[uploadData] Action',
  uploadDataSucceded: '[uploadDataSucceded] Action',
  uploadDataFailed: '[uploadDataFailed] Action',
  editData: '[editData] Action',
  editDataSucceded: '[editDataSucceded] Action',
  editDataFailed: '[editDataFailed] Action',
  createDataSucceded: '[createDataSucceded] Action',
  createDataFailed: '[createDataFailed] Action',
  deleteData: '[deleteData] Action',
  deleteDataSucceded: '[deleteDataSucceded] Action',
  deleteDataFailed: '[deleteDataFailed] Action',
  getContractTypes: '[getContractTypes] Action',
  getContractTypesSucceded: '[getContractTypesSucceded] Action',
  getContractTypesFailed: '[getContractTypesFailed] Action',
  getJobTitles: '[getJobTitles] Action',
  getJobTitlesSucceded: '[getJobTitlesSucceded] Action',
  getJobTitlesFailed: '[getJobTitlesFailed] Action',
  createSite: '[createSite] Action',
  createSiteSucceded: '[createSiteSucceded] Action',
  createSiteFailed: '[createSiteFailed] Action',
  getSites: '[getSites] Action',
  getSitesSucceded: '[getSitesSucceded] Action',
  getSitesFailed: '[getSitesFailed] Action',

  // MANAGE ADMIN USERS
  getIAMUsers: '[getIAMUsers] Action',
  getIAMUsersSucceded: '[getIAMUsersSucceded] Action',
  getIAMUsersFailed: '[getIAMUsersFailed] Action',
  getIAMUser: '[getIAMUser] Action',
  getIAMUserSucceded: '[getIAMUserSucceded] Action',
  getIAMUserFailed: '[getIAMUserFailed] Action',
  createIAMUser: '[createIAMUser] Action',
  createIAMUserSucceded: '[createIAMUserSucceded] Action',
  editIAMUserFailed: '[editIAMUserFailed] Action',
}

const initialCategoriesState: any = {
  skillsList: [],
  benefitsList: [],
  diplomesList: [],
  universitiesList: [],
  contractTypesList: [],
  jobTitlesList: [],
  workPostesList: [],
  data: {},
  citiesList: [],
  city: {},
  sitesList: [],
  site: {},
  IAMUsersList: [],
  IAMUser: {},
  countriesList: [],
  companiesDataList: [],
  specialitiesList: [],
  country: {},
  error: '',
  current_page: null,
  first_page: null,
  last_page: null,
  success: '',
}

export const reducer = persistReducer(
  {storage, key: 'v100-demo1-auth', whitelist: ['user', 'accessToken']},
  (state: any = initialCategoriesState, action: ActionWithPayload<any>) => {
    switch (action.type) {
      case actionTypes.getSkillsSucceded: {
        const skillsList = action.payload?.data
        return {
          ...state,
          skillsList,
          first_page: action.payload?.meta?.first_page,
          last_page: action.payload?.meta?.last_page,
          current_page: action.payload?.meta?.current_page,
        }
      }
      case actionTypes.getBenefitsSucceded: {
        const benefitsList = action.payload?.data
        return {
          ...state,
          benefitsList,
          first_page: action.payload?.meta?.first_page,
          last_page: action.payload?.meta?.last_page,
          current_page: action.payload?.meta?.current_page,
        }
      }
      case actionTypes.getCitiesSucceded: {
        const citiesList = action.payload?.data
        return {
          ...state,
          citiesList,
          first_page: action.payload?.meta?.first_page,
          last_page: action.payload?.meta?.last_page,
          current_page: action.payload?.meta?.current_page,
        }
      }
      case actionTypes.getCitySucceded: {
        const city = action.payload?.data
        return {...state, city}
      }
      case actionTypes.createCitySucceded: {
        return {...state, citiesList: [...state.citiesList, action.payload.data]}
      }
      case actionTypes.uploadDataSucceded: {
        return {...state, success: 'upload'}
      }
      case actionTypes.editCitySucceded: {
        return {
          ...state,
          citiesList: [
            ...state.citiesList.filter((el: any) => el.id != action?.payload?.data?.id),
            action.payload.data,
          ],
        }
      }
      case actionTypes.getSitesSucceded: {
        const sitesList = action.payload?.data
        return {
          ...state,
          sitesList,
          first_page: action.payload?.meta?.first_page,
          last_page: action.payload?.meta?.last_page,
          current_page: action.payload?.meta?.current_page,
        }
      }

      case actionTypes.createSiteSucceded: {
        return {...state, sitesList: [...state.sitesList, action.payload.data]}
      }

      // MANAGE ADMIN USERS IAM
      case actionTypes.getIAMUsersSucceded: {
        const usersList = action.payload?.data
        return {
          ...state,
          IAMUsersList: usersList,
          first_page: action.payload?.meta?.first_page,
          last_page: action.payload?.meta?.last_page,
          current_page: action.payload?.meta?.current_page,
        }
      }
      case actionTypes.getIAMUserSucceded: {
        const user = action.payload?.data
        return {...state, IAMUser: user}
      }

      case actionTypes.createIAMUserSucceded: {
        return {
          ...state,
          IAMUsersList: [
            ...state.IAMUsersList.filter((el: any) => el.id != action?.payload?.data?.id),
            action.payload.data,
          ],
        }
      }
      // END MANAGE ADMIN USERS IAM

      case actionTypes.getCountriesSucceded: {
        const countriesList = action.payload?.data
        return {
          ...state,
          countriesList,
          first_page: action.payload?.meta?.first_page,
          last_page: action.payload?.meta?.last_page,
          current_page: action.payload?.meta?.current_page,
        }
      }
      case actionTypes.getCountrySucceded: {
        const country = action.payload?.data
        return {...state, country}
      }
      case actionTypes.createCountrySucceded: {
        return {...state, countriesList: [...state.countriesList, action.payload.data]}
      }
      case actionTypes.editCountrySucceded: {
        return {
          ...state,
          countriesList: [
            ...state.countriesList.filter((el: any) => el.id != action?.payload?.data?.id),
            action.payload.data,
          ],
        }
      }
      case actionTypes.getUniversitiesSucceded: {
        const universitiesList = action.payload?.data
        return {
          ...state,
          universitiesList,
          first_page: action.payload?.meta?.first_page,
          last_page: action.payload?.meta?.last_page,
          current_page: action.payload?.meta?.current_page,
        }
      }
      case actionTypes.getSpecialitiesSucceded: {
        const specialitiesList = action.payload?.data
        return {
          ...state,
          specialitiesList,
          first_page: action.payload?.meta?.first_page,
          last_page: action.payload?.meta?.last_page,
          current_page: action.payload?.meta?.current_page,
        }
      }
      case actionTypes.getCompaniesDataSucceded: {
        const companiesDataList = action.payload?.data
        return {
          ...state,
          companiesDataList,
          first_page: action.payload?.meta?.first_page,
          last_page: action.payload?.meta?.last_page,
          current_page: action.payload?.meta?.current_page,
        }
      }
      case actionTypes.getDiplomesSucceded: {
        const diplomesList = action.payload?.data
        return {
          ...state,
          diplomesList,
          first_page: action.payload?.meta?.first_page,
          last_page: action.payload?.meta?.last_page,
          current_page: action.payload?.meta?.current_page,
        }
      }
      case actionTypes.getWorkPostesSucceded: {
        const workPostesList = action.payload?.data
        return {
          ...state,
          workPostesList,
          first_page: action.payload?.meta?.first_page,
          last_page: action.payload?.meta?.last_page,
          current_page: action.payload?.meta?.current_page,
        }
      }
      case actionTypes.getContractTypesSucceded: {
        const contractTypesList = action.payload?.data
        return {
          ...state,
          contractTypesList,
          first_page: action.payload?.meta?.first_page,
          last_page: action.payload?.meta?.last_page,
          current_page: action.payload?.meta?.current_page,
        }
      }
      case actionTypes.getJobTitlesSucceded: {
        const jobTitlesList = action.payload?.data
        return {
          ...state,
          jobTitlesList,
          first_page: action.payload?.meta?.first_page,
          last_page: action.payload?.meta?.last_page,
          current_page: action.payload?.meta?.current_page,
        }
      }
      case actionTypes.getDataSucceded: {
        const data = action.payload?.data
        return {...state, data}
      }
      case actionTypes.createDataSucceded: {
        if (action?.payload?.data?.type === 'skill') {
          return {...state, skillsList: [...state.skillsList, action.payload.data]}
        }
        if (action?.payload?.data?.type === 'benefit') {
          return {...state, benefitsList: [...state.benefitsList, action.payload.data]}
        }
        if (action?.payload?.data?.type === 'company') {
          return {...state, companiesDataList: [...state.companiesDataList, action.payload.data]}
        }
        if (action?.payload?.data?.type === 'speciality') {
          return {...state, specialitiesList: [...state.specialitiesList, action.payload.data]}
        }
        if (action?.payload?.data?.type === 'university') {
          return {...state, universitiesList: [...state.universitiesList, action.payload.data]}
        }
        if (action?.payload?.data?.type === 'diplome') {
          return {...state, diplomesList: [...state.diplomesList, action.payload.data]}
        }
        if (action?.payload?.data?.type === 'workPoste') {
          return {...state, workPostesList: [...state.workPostesList, action.payload.data]}
        }
        if (action?.payload?.data?.type === 'contractType') {
          return {...state, contractTypesList: [...state.contractTypesList, action.payload.data]}
        }
        if (action?.payload?.data?.type === 'jobTitle') {
          return {...state, jobTitlesList: [...state.jobTitlesList, action.payload.data]}
        }

        break
      }
      case actionTypes.editDataSucceded: {
        if (action?.payload?.data?.type == 'skill') {
          return {
            ...state,
            data: {},
            skillsList: [
              ...state.skillsList.filter((el: any) => el.id != action?.payload?.data?.id),
              action.payload.data,
            ],
          }
          break
        }
        if (action?.payload?.data?.type == 'benefit') {
          return {
            ...state,
            data: {},
            benefitsList: [
              ...state.benefitsList.filter((el: any) => el.id != action?.payload?.data?.id),
              action.payload.data,
            ],
          }
          break
        }
        if (action?.payload?.data?.type == 'university') {
          return {
            ...state,
            data: {},
            universitiesList: [
              ...state.universitiesList.filter((el: any) => el.id != action?.payload?.data?.id),
              action.payload.data,
            ],
          }
          break
        }
        if (action?.payload?.data?.type == 'company') {
          return {
            ...state,
            data: {},
            companiesDataList: [
              ...state.companiesDataList.filter((el: any) => el.id != action?.payload?.data?.id),
              action.payload.data,
            ],
          }
          break
        }
        if (action?.payload?.data?.type == 'speciality') {
          return {
            ...state,
            data: {},
            specialitiesList: [
              ...state.specialitiesList.filter((el: any) => el.id != action?.payload?.data?.id),
              action.payload.data,
            ],
          }
          break
        }
        if (action?.payload?.data?.type == 'diplome') {
          return {
            ...state,
            data: {},
            diplomesList: [
              ...state.diplomesList.filter((el: any) => el.id != action?.payload?.data?.id),
              action.payload.data,
            ],
          }
          break
        }
        if (action?.payload?.data?.type == 'workPoste') {
          return {
            ...state,
            data: {},
            skillsList: [
              ...state.workPostesList.filter((el: any) => el.id != action?.payload?.data?.id),
              action.payload.data,
            ],
          }
          break
        }
        if (action?.payload?.data?.type == 'contractType') {
          return {
            ...state,
            data: {},
            skillsList: [
              ...state.contractTypesList.filter((el: any) => el.id != action?.payload?.data?.id),
              action.payload.data,
            ],
          }
          break
        }
        if (action?.payload?.data?.type == 'jobTitle') {
          return {
            ...state,
            data: {},
            skillsList: [
              ...state.jobTitlesList.filter((el: any) => el.id != action?.payload?.data?.id),
              action.payload.data,
            ],
          }
          break
        }

        break
      }

      default:
        return state
    }
  }
)

export const actions = {
  uploadData: (data: any) => ({type: actionTypes.uploadData, payload: data}),
  getDataSucceded: (data: any) => ({type: actionTypes.getDataSucceded, payload: data}),
  getData: (data: any) => ({type: actionTypes.getData, payload: data}),
  getBenefitsSucceded: (data: any) => ({type: actionTypes.getBenefitsSucceded, payload: data}),
  getBenefits: (filter?: any) => ({type: actionTypes.getBenefits, payload: filter}),
  getSkillsSucceded: (data: any) => ({type: actionTypes.getSkillsSucceded, payload: data}),
  getSkills: (filter?: any) => ({type: actionTypes.getSkills, payload: filter}),
  getSpecialitiesSucceded: (data: any) => ({
    type: actionTypes.getSpecialitiesSucceded,
    payload: data,
  }),
  getSpecialities: (filter?: any) => ({type: actionTypes.getSpecialities, payload: filter}),
  getCompaniesDataSucceded: (data: any) => ({
    type: actionTypes.getCompaniesDataSucceded,
    payload: data,
  }),
  getCompaniesData: (filter?: any) => ({type: actionTypes.getCompaniesData, payload: filter}),
  getUniversitiesSucceded: (data: any) => ({
    type: actionTypes.getUniversitiesSucceded,
    payload: data,
  }),
  getUniversities: (filter?: any) => ({type: actionTypes.getUniversities, payload: filter}),
  getDiplomesSucceded: (data: any) => ({type: actionTypes.getDiplomesSucceded, payload: data}),
  getDiplomes: (filter?: any) => ({type: actionTypes.getDiplomes, payload: filter}),
  getWorkPostesSucceded: (data: any) => ({type: actionTypes.getWorkPostesSucceded, payload: data}),
  getWorkPostes: (filter?: any) => ({type: actionTypes.getWorkPostes, payload: filter}),
  getContractTypesSucceded: (data: any) => ({
    type: actionTypes.getContractTypesSucceded,
    payload: data,
  }),
  getContractTypes: (filter?: any) => ({type: actionTypes.getContractTypes, payload: filter}),
  getJobTitlesSucceded: (data: any) => ({type: actionTypes.getJobTitlesSucceded, payload: data}),
  getJobTitles: () => ({type: actionTypes.getJobTitles}),
  createDataSucceded: (data: any) => ({type: actionTypes.createDataSucceded, payload: data}),
  createData: (data: any, history: any) => ({
    type: actionTypes.createData,
    payload: data,
    history: history,
  }),
  editData: (data: any, history: any) => ({
    type: actionTypes.editData,
    payload: data,
    history: history,
  }),
  editDataSucceded: (data: any) => ({type: actionTypes.editDataSucceded, payload: data}),
  deleteData: (data: any) => ({type: actionTypes.deleteData, payload: data}),
  getCitiesSucceded: (data: any) => ({type: actionTypes.getCitiesSucceded, payload: data}),
  getCities: (filter?: any) => ({type: actionTypes.getCities, payload: filter}),
  getCitySucceded: (data: any) => ({type: actionTypes.getCitySucceded, payload: data}),
  getCity: (data: any) => ({type: actionTypes.getCity, payload: data}),
  createCitySucceded: (data: any) => ({type: actionTypes.createCitySucceded, payload: data}),
  createCity: (data: any, history: any) => ({
    type: actionTypes.createCity,
    payload: data,
    history: history,
  }),
  getSitesSucceded: (data: any) => ({type: actionTypes.getSitesSucceded, payload: data}),
  getSites: (filter?: any) => ({type: actionTypes.getSites, payload: filter}),
  createSiteSucceded: (data: any) => ({type: actionTypes.createSiteSucceded, payload: data}),
  createSite: (data: any, history: any) => ({
    type: actionTypes.createSite,
    payload: data,
    history: history,
  }),
  editCity: (data: any, history: any) => ({
    type: actionTypes.editCity,
    payload: data,
    history: history,
  }),
  editCitySucceded: (data: any) => ({type: actionTypes.editCitySucceded, payload: data}),
  deleteCity: (data: any) => ({type: actionTypes.deleteCity, payload: data}),
  getCountriesSucceded: (data: any) => ({type: actionTypes.getCountriesSucceded, payload: data}),
  getCountries: (filter?: any) => ({type: actionTypes.getCountries, payload: filter}),
  getCountrySucceded: (data: any) => ({type: actionTypes.getCountrySucceded, payload: data}),
  getCountry: (data: any) => ({type: actionTypes.getCountry, payload: data}),
  createCountrySucceded: (data: any) => ({type: actionTypes.createCountrySucceded, payload: data}),
  createCountry: (data: any, history: any) => ({
    type: actionTypes.createCountry,
    payload: data,
    history: history,
  }),
  editCountry: (data: any, history: any) => ({
    type: actionTypes.editCountry,
    payload: data,
    history: history,
  }),
  editCountrySucceded: (data: any) => ({type: actionTypes.editCountrySucceded, payload: data}),
  deleteCountry: (data: any) => ({type: actionTypes.deleteCountry, payload: data}),
  store: () => ({type: 'def'}),

  // MANAGE ADMIN USERS IAM
  getIAMUsersSucceded: (data: any) => ({type: actionTypes.getIAMUsersSucceded, payload: data}),
  getIAMUsers: (filter?: any) => ({type: actionTypes.getIAMUsers, payload: filter}),
  getIAMUserSucceded: (data: any) => ({type: actionTypes.getIAMUserSucceded, payload: data}),
  getIAMUser: (data: any) => ({type: actionTypes.getIAMUser, payload: data}),
  createIAMUser: (data: any, history: any) => ({
    type: actionTypes.createIAMUser,
    payload: data,
    history,
  }),
  createIAMUserSucceded: (data: any) => ({type: actionTypes.createIAMUserSucceded, payload: data}),
  // END MANAGE ADMIN USERS IAM
}

export function* saga() {
  yield takeEvery(actionTypes.uploadData, function* uploadDataSaga(payload: any) {
    const {data} = yield uploadDataApi(payload.payload)

    yield put(actions.getBenefitsSucceded(data))
  })
  yield takeEvery(actionTypes.getBenefits, function* getBenefitsSaga(payload: any) {
    const {data} = yield getBenefitsApi(payload?.payload?.page, payload?.payload?.filter)
    yield put(actions.getBenefitsSucceded(data))
  })
  yield takeEvery(actionTypes.getSkills, function* getSkillsSaga(payload: any) {
    const {data} = yield getSkillsApi(payload?.payload?.page, payload?.payload?.filter)
    yield put(actions.getSkillsSucceded(data))
  })
  yield takeEvery(actionTypes.getSpecialities, function* getSpecialitiesSaga(payload: any) {
    const {data} = yield getSpecialitiesApi(payload?.payload?.page, payload?.payload?.filter)
    yield put(actions.getSpecialitiesSucceded(data))
  })
  yield takeEvery(actionTypes.getCompaniesData, function* getCompaniesDatasSaga(payload: any) {
    const {data} = yield getCompaniesDataApi(payload?.payload?.page, payload?.payload?.filter)
    yield put(actions.getCompaniesDataSucceded(data))
  })
  yield takeEvery(actionTypes.getUniversities, function* getUniversitiessSaga(payload: any) {
    const {data} = yield getUniversitiesApi(payload?.payload?.page, payload?.payload?.filter)
    yield put(actions.getUniversitiesSucceded(data))
  })
  yield takeEvery(actionTypes.getDiplomes, function* getDiplomesSaga(payload: any) {
    const {data} = yield getDiplomesApi(payload?.payload?.page, payload?.payload?.filter)
    yield put(actions.getDiplomesSucceded(data))
  })
  yield takeEvery(actionTypes.getWorkPostes, function* getWorkPostesSaga(payload: any) {
    const {data} = yield getWorkPostesApi(payload?.payload?.page, payload?.payload?.filter)
    yield put(actions.getWorkPostesSucceded(data))
  })
  yield takeEvery(actionTypes.getContractTypes, function* getContractTypesSaga(payload: any) {
    const {data} = yield getContractTypesApi(payload?.payload?.page, payload?.payload?.filter)
    yield put(actions.getContractTypesSucceded(data))
  })
  yield takeEvery(actionTypes.getJobTitles, function* getJobTitlesSaga() {
    const {data} = yield getJobTitlesApi()
    yield put(actions.getJobTitlesSucceded(data))
  })
  yield takeEvery(actionTypes.getData, function* getDataSaga(data: any) {
    const dataEntry: {} = yield getData(data?.payload?.id)
    yield put(actions.getDataSucceded(dataEntry))
  })
  yield takeEvery(actionTypes.deleteData, function* deleteDataSaga(data: any) {
    yield deleteData(data?.payload?.id)
    if (data.payload?.type == 'skill') yield put(actions.getSkills())
    if (data.payload?.type == 'benefit') yield put(actions.getBenefits())
    if (data.payload?.type == 'university') yield put(actions.getUniversities())
    if (data.payload?.type == 'speciality') yield put(actions.getSpecialities())
    if (data.payload?.type == 'company') yield put(actions.getCompaniesData())
    if (data.payload?.type == 'diplome') yield put(actions.getDiplomes())
    if (data.payload?.type == 'workPoste') yield put(actions.getWorkPostes())
    if (data.payload?.type == 'contractType') yield put(actions.getContractTypes())
    if (data.payload?.type == 'jobTitle') yield put(actions.getJobTitles())
  })
  yield takeEvery(actionTypes.createData, function* createDataSaga(data: any) {
    const createdData: {} = yield createData(
      data?.payload?.name,
      data?.payload?.name_ar,
      data?.payload?.type,
      data?.payload?.parent_id
    )
    yield put(actions.createDataSucceded(createdData))
    setTimeout(() => {
      if (data.payload?.type == 'skill') data?.history?.push('/crafted/pages/data/skill')
      if (data.payload?.type == 'benefit') data?.history?.push('/crafted/pages/data/benefit')
      if (
        data.payload?.type == 'university' &&
        window.location.pathname === '/crafted/pages/data/university/create'
      )
        data?.history?.push('/crafted/pages/data/university')
      if (
        data.payload?.type == 'speciality' &&
        window.location.pathname === '/crafted/pages/data/speciality/create'
      )
        data?.history?.push('/crafted/pages/data/speciality')
      if (
        data.payload?.type == 'company' &&
        window.location.pathname === '/crafted/pages/data/company/create'
      )
        data?.history?.push('/crafted/pages/data/company')
      if (data.payload?.type == 'diplome') data?.history?.push('/crafted/pages/data/diplome')
      if (data.payload?.type == 'workPoste') data?.history?.push('/crafted/pages/data/work_poste')
      if (data.payload?.type == 'contractType')
        data?.history?.push('/crafted/pages/data/contract_type')
      if (data.payload?.type == 'jobTitle') data?.history?.push('/crafted/pages/data/job_title')
    }, 1000)
  })
  yield takeEvery(actionTypes.editData, function* editDataSaga(data: any) {
    const createdData: {} = yield editData(data?.payload)
    yield put(actions.editDataSucceded(createdData))
    setTimeout(() => {
      if (data.payload?.type == 'skill') data?.history?.push('/crafted/pages/data/skill')
      if (data.payload?.type == 'benefit') data?.history?.push('/crafted/pages/data/benefit')
      if (data.payload?.type == 'university') data?.history?.push('/crafted/pages/data/university')
      if (data.payload?.type == 'speciality') data?.history?.push('/crafted/pages/data/speciality')
      if (data.payload?.type == 'company') data?.history?.push('/crafted/pages/data/company')
      if (data.payload?.type == 'diplome') data?.history?.push('/crafted/pages/data/diplome')
      if (data.payload?.type == 'workPoste') data?.history?.push('/crafted/pages/data/work_poste')
      if (data.payload?.type == 'contractType')
        data?.history?.push('/crafted/pages/data/contract_type')
      if (data.payload?.type == 'jobTitle') data?.history?.push('/crafted/pages/data/job_title')
    }, 1000)
  })

  yield takeEvery(actionTypes.getCity, function* getCitySaga(data: any) {
    const dataEntry: {} = yield getCity(data?.payload?.id)
    yield put(actions.getCitySucceded(dataEntry))
  })
  yield takeEvery(actionTypes.editCity, function* editCitySaga(data: any) {
    const createdCity: {} = yield editCity(data?.payload)
    yield put(actions.editCitySucceded(createdCity))
    setTimeout(() => {
      data?.history?.push('/crafted/pages/data/city')
    })
  })
  yield takeEvery(actionTypes.createCity, function* createCitySaga(data: any) {
    const createdCity: {} = yield createCity(data?.payload)
    yield put(actions.createCitySucceded(createdCity))
    setTimeout(() => {
      data?.history?.push('/crafted/pages/data/city')
    })
  })
  yield takeEvery(actionTypes.deleteCity, function* deleteCitySaga(data: any) {
    yield deleteCity(data?.payload?.id)
    yield put(actions.getCities())
  })

  yield takeEvery(actionTypes.getCities, function* getCitiesSaga(payload: any) {
    const {data} = yield getCitiesApi(payload?.payload?.page, payload?.payload?.filter)
    yield put(actions.getCitiesSucceded(data))
  })

  yield takeEvery(actionTypes.getCountry, function* getCountrySaga(data: any) {
    const dataEntry: {} = yield getCountry(data?.payload?.id)
    yield put(actions.getCountrySucceded(dataEntry))
  })
  yield takeEvery(actionTypes.editCountry, function* editCountrySaga(data: any) {
    const createdCountry: {} = yield editCountry(data?.payload)
    yield put(actions.editCountrySucceded(createdCountry))
    setTimeout(() => {
      data?.history?.push('/crafted/pages/data/country')
    })
  })
  yield takeEvery(actionTypes.createCountry, function* createCountrySaga(data: any) {
    const createdCountry: {} = yield createCountry(data?.payload)
    yield put(actions.createCountrySucceded(createdCountry))
    setTimeout(() => {
      data?.history?.push('/crafted/pages/data/country')
    })
  })
  yield takeEvery(actionTypes.deleteCountry, function* deleteCountrySaga(data: any) {
    yield deleteCountry(data?.payload?.id)
    yield put(actions.getCountries())
  })

  yield takeEvery(actionTypes.getCountries, function* getCountriesSaga(payload: any) {
    const {data} = yield getCountriesApi(payload?.payload?.page, payload?.payload?.filter)
    yield put(actions.getCountriesSucceded(data))
  })

  yield takeEvery(actionTypes.createSite, function* createSiteSaga(data: any) {
    const createdSite: {} = yield createSite(data?.payload)
    yield put(actions.createSiteSucceded(createdSite))
    setTimeout(() => {
      data?.history?.push('/crafted/pages/data/sites')
    })
  })

  yield takeEvery(actionTypes.getSites, function* getSitesSaga(payload: any) {
    const {data} = yield getSitesApi(payload?.payload?.page, payload?.payload?.filter)
    yield put(actions.getSitesSucceded(data))
  })

  yield takeEvery(actionTypes.getIAMUsers, function* getIAMUsersSaga(payload: any) {
    const {data} = yield getIAMUsersApi(payload?.payload?.page)
    yield put(actions.getIAMUsersSucceded(data))
  })
  yield takeEvery(actionTypes.getIAMUser, function* getIAMUserSaga(data: any) {
    const user: any = ({} = yield getIAMUserApi(data?.payload))
    yield put(actions.getIAMUserSucceded(user))
  })
  yield takeEvery(actionTypes.createIAMUser, function* editUserSaga(data: any) {
    const createdData: {} = yield createIAMUser(data?.payload)
    yield put(actions.createIAMUserSucceded(createdData))
    setTimeout(() => {
      data?.history?.push('/crafted/pages/data/users')
    }, 1000)
  })
}
