import React, {useEffect} from 'react'
import * as Yup from 'yup'
import {Formik} from 'formik'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import * as redux from '../../redux/DataRedux'
import {RootState} from '../../../../../setup'
export function CreateSite(props: any) {
  const cities: any = useSelector<RootState>(({data}) => data.citiesList)
  const user: any = useSelector<RootState>(({auth}) => auth.user)
  const dispatch = useDispatch()
  const history = useHistory()
  useEffect(() => {
    dispatch(redux.actions.getCities())
  }, [])

  const handleSubmit = (values: any, e: any) => {
    e.preventDefault()
    console.log('values+++++++', values)
    values.admin_id = user?.user?.id
    dispatch(redux.actions.createSite({...values, admin_id: user?.user?.id}, history))
  }

  console.log('user++++++++++++++', user?.user?.id)
  return (
    <>
      <Formik
        onSubmit={(values, {setSubmitting}) => {
          setSubmitting(true)
        }}
        initialValues={{
          name: '',
          city_id: '',
          admin_id: user?.id,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Required'),
          city_id: Yup.string().required('Required'),
        })}
      >
        {({
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleReset,
          setSubmitting,
          setFieldValue,
          resetForm,
        }) => (
          <form className='form'>
            <div className='w-100'>
              {/*begin::Heading */}
              {/*begin::Heading */}

              {/*begin::Form Group */}
              <div className='fv-row mb-12 col-3'>
                <label className='fs-6 fw-bolder text-dark form-label'>Name</label>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  name='name'
                  placeholder=''
                  value={values.name}
                  onChange={handleChange}
                />
              </div>

              {cities?.length > 0 && cities[0].name && (
                <div className='fv-row mb-12 col-3'>
                  <label className='fs-6 fw-bolder text-dark form-label'>City Name</label>
                  <select
                    className='form-select'
                    aria-label='Select example'
                    value={values.city_id}
                    name='city_id'
                    onChange={handleChange}
                  >
                    <option></option>
                    {cities.map((p: any, i: number) => {
                      return (
                        <option key={i} value={p.id}>
                          {p.name}
                        </option>
                      )
                    })}
                  </select>
                </div>
              )}
            </div>

            <button
              className='btn btn-primary'
              onClick={(e) => {
                setSubmitting(true)
                handleSubmit(values, e)
              }}
              style={{backgroundColor: '#87e8c5'}}
              type='submit'
            >
              Submit
            </button>
          </form>
        )}
      </Formik>
    </>
  )
}
