import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
export const UsersURL = `${API_URL}/users/?filter={"role":{"op":"=","value":"condidate"}}`
export const UserURL = `${API_URL}/users/`
export function getUsersApi(page: any, filter?: any) {
  return axios.get(`${API_URL}/users/paginate/?page=${page}&perPage=100&filter={"role":{"op":"=","value":"condidate"}}`)
}
export function getUserApi(data: any) {
  return axios.get<any>(UserURL+data.id)
}
export function editUser(data: any) {
  return axios.patch<any>(UserURL+data.id, data)
}