import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {Domain} from './components/domain/Domain'
import {CreateDomain} from './components/domain/CreateDomain'
import {EditDomain} from './components/domain/EditDomain'

import {Department} from './components/department/Department'
import {CreateDepartment} from './components/department/CreateDepartment'
import {EditDepartment} from './components/department/EditDepartment'

import {Poste} from './components/poste/Poste'
import {CreatePoste} from './components/poste/CreatePoste'
import {EditPoste} from './components/poste/EditPoste'

import {Unit} from './components/unit/Unit'
import {CreateUnit} from './components/unit/CreateUnit'
import {EditUnit} from './components/unit/EditUnit'

import {Process} from './components/process/Process'
import {CreateProcess} from './components/process/CreateProcess'
import {EditProcess} from './components/process/EditProcess'
const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Categories',
    path: '/crafted/pages/categories',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

    export function CategoriesPage() {
  return (
    <>
      <Switch>
        <Route path='/crafted/pages/categories/domain' exact={true} >
          <PageTitle breadcrumbs={profileBreadCrumbs} >Activity Domains</PageTitle>
          <Domain />
        </Route>
        <Route path='/crafted/pages/categories/domain/create' exact={true} >
          <PageTitle breadcrumbs={profileBreadCrumbs}>Create Domain</PageTitle>
          <CreateDomain />
        </Route>
        <Route path='/crafted/pages/categories/domain/:id' exact={true} >
          <PageTitle breadcrumbs={profileBreadCrumbs}>Edit Domain</PageTitle>
          <EditDomain />
        </Route>
        <Route path='/crafted/pages/categories/department' exact={true} >
          <PageTitle breadcrumbs={profileBreadCrumbs}>Departments</PageTitle>
          <Department />
        </Route>
        <Route path='/crafted/pages/categories/department/create' exact={true} >
          <PageTitle breadcrumbs={profileBreadCrumbs}>Create Department</PageTitle>
          <CreateDepartment />
        </Route>
        <Route path='/crafted/pages/categories/department/:id' exact={true} >
          <PageTitle breadcrumbs={profileBreadCrumbs}>Edit Department</PageTitle>
          <EditDepartment />
        </Route>
        <Route path='/crafted/pages/categories/unit' exact={true} >
          <PageTitle breadcrumbs={profileBreadCrumbs}>Units</PageTitle>
          <Unit />
        </Route>
        <Route path='/crafted/pages/categories/unit/create' exact={true} >
          <PageTitle breadcrumbs={profileBreadCrumbs}>Create Unit</PageTitle>
          <CreateUnit />
        </Route>
        <Route path='/crafted/pages/categories/unit/:id' exact={true} >
          <PageTitle breadcrumbs={profileBreadCrumbs}>Edit Unit</PageTitle>
          <EditUnit />
        </Route>
        <Route path='/crafted/pages/categories/process' exact={true} >
          <PageTitle breadcrumbs={profileBreadCrumbs}>Process</PageTitle>
          <Process />
        </Route>
        <Route path='/crafted/pages/categories/process/create' exact={true} >
          <PageTitle breadcrumbs={profileBreadCrumbs}>Create Process</PageTitle>
          <CreateProcess />
        </Route>
        <Route path='/crafted/pages/categories/process/:id' exact={true} >
          <PageTitle breadcrumbs={profileBreadCrumbs}>Edit Process</PageTitle>
          <EditProcess />
        </Route>
        <Route path='/crafted/pages/categories/poste' exact={true} >
          <PageTitle breadcrumbs={profileBreadCrumbs}>Postes</PageTitle>
          <Poste />
        </Route>
        <Route path='/crafted/pages/categories/poste/create' exact={true} >
          <PageTitle breadcrumbs={profileBreadCrumbs}>Create Poste</PageTitle>
          <CreatePoste />
        </Route>
        <Route path='/crafted/pages/categories/poste/:id' exact={true} >
          <PageTitle breadcrumbs={profileBreadCrumbs}>Edit Poste</PageTitle>
          <EditPoste />
        </Route>
        <Redirect from='/crafted/pages/categories' exact={true} to='/crafted/pages/categories/domain' />
      </Switch>
    </>
  )
}

export default CategoriesPage
