import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {Formik} from 'formik'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import * as redux from '../../redux/CategoryRedux'
import {useHistory, useParams} from 'react-router-dom'
export function EditUnit(props: any) {
  const dispatch = useDispatch()
  const history = useHistory()
  const departments: any = useSelector<RootState>(({category}) => category.departmentsList)
  const category: any = useSelector<RootState>(({category}) => category.category)
  const params: any = useParams()
  useEffect(() => {
    dispatch(redux.actions.getCategory({id: params.id}))
    dispatch(redux.actions.getDepartments())
  }, [])
  const handleSubmit = (values: any, e: any) => {
    e.preventDefault()
    dispatch(redux.actions.editCategory(values, history))
  }
  return category && category.name ? (
    <>
      <Formik
        onSubmit={(values, {setSubmitting}) => {
          setSubmitting(true)
        }}
        initialValues={{
          id: category ? category.id || null : null,
          name: category ? category.name || '' : '',
          name_ar: category ? category.name_ar || '' : '',
          parent_id: category ? category.parent_id || null : null,
          status: category ? category.status || '' : '',
          type: 'unit',
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Required'),
        })}
      >
        {({
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleReset,
          setSubmitting,
          setFieldValue,
          resetForm,
        }) => (
          <form className='form'>
            <div className='w-100'>
              {/*begin::Heading */}
              {/*begin::Heading */}

              {/*begin::Form Group */}
              <div className='fv-row mb-12 col-3'>
                <label className='fs-6 fw-bolder text-dark form-label'>Unit Name</label>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  name='name'
                  value={values.name}
                  onChange={handleChange}
                />
              </div>
              <div className='fv-row mb-12 col-3'>
                <label className='fs-6 fw-bolder text-dark form-label'>Unit Arabic Name</label>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  name='name_ar'
                  value={values.name_ar}
                  onChange={handleChange}
                />
              </div>
              <div className='fv-row mb-12 col-3'>
                {values.status == 'active' && (
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Block</label>
                )}
                {values.status != 'active' && (
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Activate</label>
                )}

                <div className='col-lg-8 d-flex align-items-center'>
                  <div className='form-check form-check-solid form-switch fv-row'>
                    <input
                      className='form-check-input w-45px h-30px'
                      type='checkbox'
                      id='allowmarketing'
                      checked={values.status == 'active'}
                      onChange={() => {
                        if (values.status == 'active') setFieldValue('status', 'pending')
                        else setFieldValue('status', 'active')
                      }}
                    />
                    <label className='form-check-label'></label>
                  </div>
                </div>
              </div>
            </div>
            {departments?.length > 0 && departments[0].name && (
              <div className='fv-row mb-12 col-3'>
                <label className='fs-6 fw-bolder text-dark form-label'>Department Name</label>
                <select
                  className='form-select'
                  aria-label='Select example'
                  value={values.parent_id}
                  name='parent_id'
                  onChange={handleChange}
                >
                  <option></option>
                  {departments.map((department: any, i: number) => {
                    return (
                      <option key={i} value={department.id}>
                        {department.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            )}

            <button
              className='btn btn-primary'
              style={{backgroundColor: '#87e8c5'}}
              onClick={(e) => {
                setSubmitting(true)
                handleSubmit(values, e)
              }}
              type='submit'
            >
              Submit
            </button>
          </form>
        )}
      </Formik>
    </>
  ) : (
    <></>
  )
}
