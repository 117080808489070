/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useParams} from 'react-router-dom'
import {RootState} from '../../../../../../setup'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {DataTable} from './TablesWidget5'
import * as redux from '../../../redux/CompanyRedux'

export function Overview() {
  const dispatch = useDispatch()
  const params: any = useParams()
  const user: any = useSelector<RootState>(({company}) => company.companyUser)
  useEffect(() => {
    dispatch(redux.actions.getCompanyUser({id: params.id}))
  }, [])
  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Account Details</h3>
          </div>
        </div>

        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Full Name</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                {user.first_name + ' ' + user.last_name}
              </span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Contact Email</label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{user.email || '-'}</span>

              {
                //<span className='badge badge-success'>Verified</span>
              }
            </div>
          </div>

<div className='row mb-7'>
  <label className='col-lg-4 fw-bold text-muted'>Birth Date</label>

  <div className='col-lg-8 d-flex align-items-center'>
    <span className='fw-bolder fs-6 me-2'>{user.birthday || '-'}</span>

    {
      //<span className='badge badge-success'>Verified</span>
    }
  </div>
</div>

<div className='row mb-7'>
  <label className='col-lg-4 fw-bold text-muted'>Role</label>

  <div className='col-lg-8 d-flex align-items-center'>
    <span className='fw-bolder fs-6 me-2'>{user.role?.name || '-'}</span>

    {
      //<span className='badge badge-success'>Verified</span>
    }
  </div>
</div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Contact Phone</label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{user.phone || '-'}</span>

              {
                //<span className='badge badge-success'>Verified</span>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
