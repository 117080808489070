import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
//const API_URL = "https://int.api.forstec.tn"

export const GET_JOBS = `${API_URL}/jobOffers`
export const GET_COMPANIES = `${API_URL}/companies`
export const CREATE_JOB = `${API_URL}/jobOffers/admin/store/`
export const UPDATE_JOB = `${API_URL}/jobOffers/`
export const GET_APPLICATIONS = `${API_URL}/applications`
export const GET_CANDIDATE = `${API_URL}/users/`
export const API_INVITATIONS = `${API_URL}/invitations`
export const API_INTERVIEW = `${API_URL}/interviews`
export const API_FILES = `${API_URL}/files`
export const GET_CATEGORIES = `${API_URL}/categories`

// Server should return CompanyModel

// export function getJobs(draft: boolean, value: number) {
//   if (draft) {
//     return axios.get<any>(
//       GET_JOBS +
//         `?filter={"company_id":{"op":"=","value":"${value}"}, "is_template":{"op":"=","value":"true"},"status":{"op":"!=","value":"archieved"}}`
//     )
//   } else {
//     return axios.get<any>(
//       GET_JOBS +
//         `?filter={"company_id":{"op":"=","value":"${value}"},"status":{"op":"!=","value":"archieved"}}`
//     )
//   }
// }
export function getJobs(draft?: boolean) {
  return axios.get<any>(
    GET_JOBS +
      `?filter={${
        draft ? `"is_template":{"op":"=","value":"true"},` : ''
      }"status":{"op":"!=","value":"archieved"}}`
  )
}
export function getCompanies() {
  return axios.get<any>(GET_COMPANIES + `?filter={"status":{"op":"!=","value":"archieved"}}`)
}
export function getTimeSlots(value: number) {
  return axios.get<any>(GET_JOBS + `/time_slots/${value}`)
}
export function getJobsByFilter(
  company_id: any,
  draft: boolean,
  value: string,
  status: string,
  site_id: string
) {
  if (!draft) {
    if (status && company_id && site_id && value) {
      return axios.get<any>(
        GET_JOBS +
          `?filter={"company_id":{"op":"=","value":"${company_id}"}, "title":{"op":"ilike","value":"%25${value}%25"},"site_id":{"op":"=","value":"${site_id}"} ,"status":{"op":"=","value":"${status}"}}`
      )
    } else if (status && company_id && site_id) {
      return axios.get<any>(
        GET_JOBS +
          `?filter={"company_id":{"op":"=","value":"${company_id}"}, "site_id":{"op":"=","value":"${site_id}"} ,"status":{"op":"=","value":"${status}"}}`
      )
    } else if (status && value) {
      return axios.get<any>(
        GET_JOBS +
          `?filter={"title":{"op":"ilike","value":"%25${value}%25"}, "status":{"op":"=","value":"${status}"}}`
      )
    } else if (value && company_id && site_id) {
      return axios.get<any>(
        GET_JOBS +
          `?filter={"company_id":{"op":"=","value":"${company_id}"}, "title":{"op":"ilike","value":"%25${value}%25"},"site_id":{"op":"=","value":"${site_id}"}, "status":{"op":"!=","value":"archieved"}}`
      )
    } else if (value) {
      return axios.get<any>(
        GET_JOBS +
          `?filter={"title":{"op":"ilike","value":"%25${value}%25"}, "status":{"op":"!=","value":"archieved"}}`
      )
    } else if (company_id && site_id) {
      return axios.get<any>(
        GET_JOBS +
          `?filter={"company_id":{"op":"=","value":"${company_id}"}, "site_id":{"op":"=","value":"${site_id}"}, "status":{"op":"!=","value":"archieved"}}`
      )
    } else if (company_id && status) {
      return axios.get<any>(
        GET_JOBS +
          `?filter={"company_id":{"op":"=","value":"${company_id}"}, "status":{"op":"=","value":"${status}"}}`
      )
    } else if (company_id) {
      return axios.get<any>(
        GET_JOBS +
          `?filter={"company_id":{"op":"=","value":"${company_id}"}, "status":{"op":"!=","value":"archieved"}}`
      )
    } else if (status) {
      return axios.get<any>(GET_JOBS + `?filter={"status":{"op":"=","value":"${status}"}}`)
    } else if (company_id) {
      return axios.get<any>(
        GET_JOBS +
          `/paginate?filter={"company_id":{"op":"=", "value":"${company_id}"},"status":{"op":"=","value":"${status}"}}`
      )
    } else {
      return axios.get<any>(GET_JOBS + `/`)
    }
  } else {
    return axios.get<any>(
      GET_JOBS +
        `?filter={"is_template":{"op":"=","value":"true"}, "title":{"op":"=","value":"${value}"}, "status":{"op":"!=","value":"archieved"}}`
    )
  }
}

export function getJobById(id: any) {
  return axios.get<any>(GET_JOBS + '/' + id)
}

//API for getteing job offers for a user and eleminate the ones he already posted in
export function getJobOffersSuggestions(userId: any, companyId: any) {
  return axios.get<any>(GET_JOBS + `/available/${companyId}/${userId}`)
}

export function getJobApplications(id: any, filter: any) {
  if (filter) {
    return axios.get<any>(
      GET_APPLICATIONS + `?filter={"job_offer_id":{"op":"=","value":"${id}"}}&${filter}`
    )
  } else {
    return axios.get<any>(GET_APPLICATIONS + `?filter={"job_offer_id":{"op":"=","value":"${id}"}}`)
  }
}
export function createJob(data: any) {
  return axios.post<any>(CREATE_JOB, data)
}

export function sendInvitations(data: any) {
  return axios.post<any>(API_INVITATIONS, data)
}

export function inviteInterview(data: any) {
  return axios.post<any>(API_INTERVIEW, data)
}

export function getJobInvitations(id: any) {
  return axios.get<any>(API_INVITATIONS + `?filter={"job_offer_id":{"op":"=","value":"${id}"}}`)
}
export function deleteJob(id: any) {
  return axios.patch<any>(CREATE_JOB + id, {status: 'archieved'})
}

export function updateJob(id: any, data: any) {
  return axios.patch<any>(UPDATE_JOB + id, data)
}

export function getCandidate(id: any) {
  return axios.get<any>(GET_CANDIDATE + id)
}

export function getCandidateFiles(id: any) {
  return axios.get<any>(API_FILES + `?filter={ "user_id":{"op":"=","value":"${id}"}}`)
}

export function acceptCandidate(id: any) {
  return axios.patch<any>(GET_APPLICATIONS + `/${id}`, {status: 'accepted'})
}

export function getCategories(company_id: any) {
  return axios.get<any>(GET_CATEGORIES+`?filter={"status":{"orWhere": true,"op":"=","value":"active"}}&nestedfilter={"user":{"filters":[{"field":"company_id","op":"=","value":"${company_id}"}]}}`)
}