import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest, select, takeEvery} from 'redux-saga/effects'
import {createCategory, deleteCategory, editCategory, getCategory, getDepartmentsApi, getDomainsApi, getPostesApi, getProcessApi, getUnitsApi} from "./service";
export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  getDomains: '[getDomains] Action',
  getDomainsSucceded: '[getDomainsSucceded] Action',
  getDomainsFailed: '[getDomainsFailed] Action',
  getDepartments: '[getDepartments] Action',
  getDepartmentsSucceded: '[getDepartmentsSucceded] Action',
  getDepartmentsFailed: '[getDepartmentsFailed] Action',
  getCategory: '[getCategory] Action',
  getCategorySucceded: '[getCategorysucceded] Action',
  getCategoryFailed: '[getCategoryFailed] Action',
  createCategory: '[createCategory] Action',
  editCategory: '[editCategory] Action',
  editCategorySucceded: '[editCategorySucceded] Action',
  editCategoryFailed: '[editCategoryFailed] Action',
  createCategorySucceded: '[createCategorySucceded] Action',
  createCategoryFailed: '[createCategoryFailed] Action',
  deleteCategory: '[deleteCategory] Action',
  deleteCategorySucceded: '[deleteCategorySucceded] Action',
  deleteCategoryFailed: '[deleteCategoryFailed] Action',
  getUnits: '[getUnits] Action',
  getUnitsSucceded: '[getUnitsSucceded] Action',
  getUnitsFailed: '[getUnitsFailed] Action',
  getProcess: '[getProcess] Action',
  getProcessSucceded: '[getProcessSucceded] Action',
  getProcessFailed: '[getProcessFailed] Action',
  getPostes: '[getPostes] Action',
  getPostesSucceded: '[getPostesSucceded] Action',
  getPostesFailed: '[getPostesFailed] Action',
}

const initialCategoriesState: any = {
  domainsList : null,
  unitsList : null,
  processList : null,
  postesList : null,
  departmentsList : null,
  current_page : null,
  first_page : null,
  last_page : null,
  category : {}
}

export const reducer = persistReducer(
  {storage, key: 'v100-demo1-auth', whitelist: ['user', 'accessToken']},
  (state: any = initialCategoriesState, action: ActionWithPayload<any>) => {
    switch (action.type) {
      case actionTypes.getDomainsSucceded: {
        const domainsList = action.payload?.data
        return {...state, domainsList, first_page: action.payload?.meta?.first_page, last_page: action.payload?.meta?.last_page, current_page: action.payload?.meta?.current_page}
      }
      case actionTypes.getDepartmentsSucceded: {
        const departmentsList = action.payload?.data
        return {...state, departmentsList, first_page: action.payload?.meta?.first_page, last_page: action.payload?.meta?.last_page, current_page: action.payload?.meta?.current_page}
      }
      case actionTypes.getUnitsSucceded: {
        const unitsList = action.payload?.data
        return {...state, unitsList, first_page: action.payload?.meta?.first_page, last_page: action.payload?.meta?.last_page, current_page: action.payload?.meta?.current_page}
      }
      case actionTypes.getProcessSucceded: {
        const processList = action.payload?.data
        return {...state, processList, first_page: action.payload?.meta?.first_page, last_page: action.payload?.meta?.last_page, current_page: action.payload?.meta?.current_page}
      }
      case actionTypes.getPostesSucceded: {
        const postesList = action.payload?.data
        return {...state, postesList, first_page: action.payload?.meta?.first_page, last_page: action.payload?.meta?.last_page, current_page: action.payload?.meta?.current_page}
      }
      case actionTypes.getCategorySucceded: {
        const category = action.payload?.data
        return {...state, category}
      }
      case actionTypes.createCategorySucceded: {
        if(action?.payload?.data?.type === 'domain'){
        return {...state,domainsList: [...state.domainsList, action.payload.data]}}
        if(action?.payload?.data?.type === 'department'){
        return {...state,domainsList: [...state.departmentsList, action.payload.data]}}
        if(action?.payload?.data?.type === 'unit'){
        return {...state,domainsList: [...state.unitsList, action.payload.data]}}
        if(action?.payload?.data?.type === 'process'){
        return {...state,domainsList: [...state.processList, action.payload.data]}}
        if(action?.payload?.data?.type === 'poste'){
        return {...state,domainsList: [...state.postesList, action.payload.data]}}
        
        break;
      }
      case actionTypes.editCategorySucceded: {
        if(action?.payload?.data?.type == 'domain'){
        return {...state, category : {},domainsList: [...state.domainsList.filter((el: any)=> el.id != action?.payload?.data?.id), action.payload.data]}
        break;}
        if(action?.payload?.data?.type == 'department'){
        return {...state, category : {},domainsList: [...state.departmentsList.filter((el: any)=> el.id != action?.payload?.data?.id), action.payload.data]}
        break;}
        if(action?.payload?.data?.type == 'unit'){
        return {...state, category : {},domainsList: [...state.unitsList.filter((el: any)=> el.id != action?.payload?.data?.id), action.payload.data]}
        break;}
        if(action?.payload?.data?.type == 'process'){
        return {...state, category : {},domainsList: [...state.processList.filter((el: any)=> el.id != action?.payload?.data?.id), action.payload.data]}
        break;}
        if(action?.payload?.data?.type == 'poste'){
        return {...state, category : {},domainsList: [...state.postesList.filter((el: any)=> el.id != action?.payload?.data?.id), action.payload.data]}
        break;}
        
        break;
      }

      default:
        return state
    }
  }
)

export const actions = {
  getCategorySucceded: (data : any) => ({type: actionTypes.getCategorySucceded, payload: data}),
  getCategory: (data : any)  => ({type: actionTypes.getCategory, payload: data}),
  getDomainsSucceded: (data : any) => ({type: actionTypes.getDomainsSucceded, payload: data}),
  getDomains: (filter?: any) => ({type: actionTypes.getDomains, payload: filter}),
  getDepartmentsSucceded: (data : any) => ({type: actionTypes.getDepartmentsSucceded, payload: data}),
  getDepartments: (filter?: any) => ({type: actionTypes.getDepartments, payload: filter}),
  getUnitsSucceded: (data : any) => ({type: actionTypes.getUnitsSucceded, payload: data}),
  getUnits: (filter?: any) => ({type: actionTypes.getUnits, payload: filter}),
  getProcessSucceded: (data : any) => ({type: actionTypes.getProcessSucceded, payload: data}),
  getProcess: (filter?: any) => ({type: actionTypes.getProcess, payload: filter}),
  getPostesSucceded: (data : any) => ({type: actionTypes.getPostesSucceded, payload: data}),
  getPostes: (filter?: any) => ({type: actionTypes.getPostes, payload: filter}),
  createCategorySucceded: (data : any) => ({type: actionTypes.createCategorySucceded, payload: data}),
  createCategory: (data : any, history : any) => ({type: actionTypes.createCategory, payload: data, history:history}),
  editCategory: (data : any, history : any) => ({type: actionTypes.editCategory, payload: data, history:history}),
  editCategorySucceded: (data : any) => ({type: actionTypes.editCategorySucceded, payload: data}),
  deleteCategory: (data : any) => ({type: actionTypes.deleteCategory, payload: data}),
  store: () => ({type: "def"}),
}

export function* saga() {
  yield takeEvery(actionTypes.getDomains, function* getDomainsSaga(payload: any) {
    const {data} = yield getDomainsApi(payload?.payload?.page, payload?.payload?.filter)
    yield put(actions.getDomainsSucceded(data))
  })
  yield takeEvery(actionTypes.getDepartments, function* getDepartmentsSaga(payload: any) {
    const {data} = yield getDepartmentsApi(payload?.payload?.page, payload?.payload?.filter)
    yield put(actions.getDepartmentsSucceded(data))
  })
  yield takeEvery(actionTypes.getUnits, function* getUnitsSaga(payload: any) {
    const {data} = yield getUnitsApi(payload?.payload?.page, payload?.payload?.filter)
    yield put(actions.getUnitsSucceded(data))
  })
  yield takeEvery(actionTypes.getProcess, function* getProcessSaga(payload: any) {
    const {data} = yield getProcessApi(payload?.payload?.page, payload?.payload?.filter)
    yield put(actions.getProcessSucceded(data))
  })
  yield takeEvery(actionTypes.getPostes, function* getPostesSaga(payload: any) {
    const {data} = yield getPostesApi(payload?.payload?.page, payload?.payload?.filter)
    yield put(actions.getPostesSucceded(data))
  })
  yield takeEvery(actionTypes.getCategory, function* getCategorySaga(data: any) {
    const category:{} = yield getCategory(data?.payload?.id)
    yield put(actions.getCategorySucceded(category))
  })
  yield takeEvery(actionTypes.deleteCategory, function* deleteCategorySaga(data: any) {
    yield deleteCategory(data?.payload?.id)
    if(data.payload?.type == 'domain')
    yield put(actions.getDomains())
    if(data.payload?.type == 'department')
    yield put(actions.getDepartments())
    if(data.payload?.type == 'unit')
    yield put(actions.getUnits())
    if(data.payload?.type == 'process')
    yield put(actions.getProcess())
    if(data.payload?.type == 'poste')
    yield put(actions.getPostes())
  })
  yield takeEvery(actionTypes.createCategory, function* createCategorySaga(data: any) {
    const createdCategory :{} = yield createCategory(data?.payload?.name,data?.payload?.name_ar,data?.payload?.type,data?.payload?.parent_id)
    yield put(actions.createCategorySucceded(createdCategory))
   setTimeout(() => {
    if(data.payload?.type == 'domain')
    data?.history?.push('/crafted/pages/categories/domain')
    if(data.payload?.type == 'department')
    data?.history?.push('/crafted/pages/categories/department')
    if(data.payload?.type == 'unit')
    data?.history?.push('/crafted/pages/categories/unit')
    if(data.payload?.type == 'process')
    data?.history?.push('/crafted/pages/categories/process')
    if(data.payload?.type == 'poste')
    data?.history?.push('/crafted/pages/categories/poste')
  }, 2000);
  })
  yield takeEvery(actionTypes.editCategory, function* editCategorySaga(data: any) {
    const createdCategory :{} = yield editCategory(data?.payload)
    yield put(actions.editCategorySucceded(createdCategory))
   setTimeout(() => {
    if(data.payload?.type == 'domain')
    data?.history?.push('/crafted/pages/categories/domain')
    if(data.payload?.type == 'department')
    data?.history?.push('/crafted/pages/categories/department')
    if(data.payload?.type == 'unit')
    data?.history?.push('/crafted/pages/categories/unit')
    if(data.payload?.type == 'process')
    data?.history?.push('/crafted/pages/categories/process')
    if(data.payload?.type == 'poste')
    data?.history?.push('/crafted/pages/categories/poste')
  }, 2000);
  })
}
