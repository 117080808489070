import React, { useEffect } from 'react'
import * as Yup from 'yup'
import {Formik} from 'formik'
import {useDispatch, useSelector} from 'react-redux'
import { useHistory } from "react-router-dom";
import * as redux from '../../redux/DataRedux'
import { RootState } from '../../../../../setup';
export function CreateCity(props :any)  {
  const countries: any = useSelector<RootState>(({data}) => data.countriesList)
  const dispatch = useDispatch()
  const history = useHistory()
  useEffect(() => {
    dispatch(redux.actions.getCountries())
  }, [])
  const handleSubmit = (values : any, e: any) => {
    e.preventDefault()
    dispatch(redux.actions.createCity(values,history))
  }
  return (
    <>
      <Formik
        onSubmit={(values, {setSubmitting}) => {
          setSubmitting(true)
        }}
        initialValues={{
          name: '',
          name_ar: '',
          country_id: ''
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Required')
        })}
      >
        {({
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleReset,
          setSubmitting,
          setFieldValue,
          resetForm,
        }) => (
          <form className='form'>
            <div className='w-100'>
              {/*begin::Heading */}
              {/*begin::Heading */}

              {/*begin::Form Group */}
              <div className='fv-row mb-12 col-3'>
                <label className='fs-6 fw-bolder text-dark form-label'>City Name</label>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  name='name'
                  placeholder=''
                  value={values.name}
                  onChange={handleChange}
                />
              </div>
              <div className='fv-row mb-12 col-3'>
                <label className='fs-6 fw-bolder text-dark form-label'>City Arabic Name</label>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  name='name_ar'
                  value={values.name_ar}
                  onChange={handleChange}
                />
              </div>
              {
                    countries?.length > 0 && countries[0].name && (
                      <div className='fv-row mb-12 col-3'>
                        <label className='fs-6 fw-bolder text-dark form-label'>Country Name</label>
                        <select
                          className='form-select'
                          aria-label='Select example'
                          value={values.country_id}
                          name='country_id'
                          onChange={handleChange}
                        >
                          <option></option>
                          {countries.map((p: any, i:number)=>
                              {
                                return <option key = {i} value={p.id}>{p.name}</option>
                              })
                          }
                        </select>
                      </div>
                      
                    )
              }
            </div>

            <button className ="btn btn-primary" onClick={((e)=>{
          setSubmitting(true); handleSubmit(values,e)})}style={{backgroundColor: '#87e8c5'}} type='submit'>Submit</button>
          </form>
        )}
      </Formik>
    </>
  )
}
