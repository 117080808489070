import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
// import {ICity, ICountry, IProfileDetails} from '../SettingsModel'
import * as Yup from 'yup'
import {setIn, useFormik} from 'formik'
import {RootState} from '../../../../../setup'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {uploadImage} from '../../redux/service'
import * as redux from '../../redux/CompanyRedux'
// import {getCountries} from '../../../data/redux/DataRedux'
import {Redirect, useHistory} from 'react-router-dom'
import Swal from 'sweetalert2'
import {useIntl} from 'react-intl'

const profileDetailsSchema = Yup.object({
  name: Yup.string()
    .typeError('You must provide company name')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols'),
  phone: Yup.string()
    .typeError('You must specify a number with 8 numbers or 12')
    .min(3, 'Minimum 8 symbols')
    .max(50, 'Maximum 12 symbols'),
  website: Yup.string()
    .typeError('You must provide website name')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols'),
  country_id: Yup.string()
    .typeError('You must select a country')
    .min(1, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols'),
  city_id: Yup.string()
    .typeError('You must select a city')
    .min(1, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols'),
  description: Yup.string()
    .typeError('Description must be provided')
    .min(3, 'Minimum 10 symbols')
    .max(50, 'Maximum 50 symbols'),
})

const CreateCompany: React.FC = () => {
  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const countriesData: any = useSelector<RootState>(({data}) => data.countriesList)
  const history = useHistory()
  const intl = useIntl()
  // useEffect(() => {
  //   if (user?.role.name !== 'admin') {
  //     history.push('/company')
  //   }
  // }, [user])

  const dispatch = useDispatch()

  const categories: any = useSelector<RootState>(({company}) => company.categories)
  const success: any = useSelector<RootState>(({company}) => company.success)
  const error: any = useSelector<RootState>(({company}) => company.error)
  const [page, setPage] = useState<any>(1)
  const [initialValues, setIntitialValues] = useState({
    name: '',
    registration_file: '',
    website: '',
    email: '',
    phone: '',
    country_id: null,
    city_id: null,
    image_url: '',
    description: '',
    domain_of_activity: '',
    size: '',
    status: 'active',
  })
  const [countries, setCountries] = useState<Array<any>>([])
  const [cities, setCities] = useState<Array<any>>([])
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState<string | null>(null)
  const [registrationNumber, setRegistrationNumber] = useState<any>({
    name: '',
    loading: false,
    link: '',
  })

  useEffect(() => {
    dispatch(redux.actions.getCountries({page: page}))
  }, [page])

  useEffect(() => {
    dispatch(redux.actions.getCategories())
  }, [])

  const imageHandler = (event: any) => {
    const file = event.target.files[0]

    if (file) {
      uploadImage(file)
        .then((response) => {
          console.log('Full response:', response)

          const {data} = response
          if (data && data.path) {
            console.log('Uploaded image path:', data.path)
            setImage(data.path)
            console.log('imageeeeeeeee', image)
          } else {
            console.error('Path not found in the response', data)
          }
        })
        .catch((error) => {
          console.error('Image upload failed', error)
        })
    } else {
      console.error('No file selected')
    }
  }
  const fileHandler = (file: any) => {
    setRegistrationNumber({...registrationNumber, loading: true})
    uploadImage(file).then(({data: {path}}) => {
      setRegistrationNumber({...registrationNumber, link: path, loading: false, name: file.name})
    })
  }

  useEffect(() => {
    if (countriesData.length > 0) {
      countriesData.map((country: any) => setCities([...country.cities]))
    }
  }, [countriesData])

  const formik = useFormik<any>({
    initialValues,
    enableReinitialize: true,
    validationSchema: profileDetailsSchema,
    onSubmit: (values) => {
      setLoading(true)
      setTimeout(() => {
        if (image) {
          values.image_url = image
          values.cover_image_url = image
        }
        values.registration_file = registrationNumber.link
        values.registration_number = registrationNumber.link

        dispatch(redux.actions.createCompany(values, history))
        setLoading(false)
      }, 1000)
    },
  })

  // useEffect(() => {
  //   if (success == 'update_company') {
  //     Swal.fire(
  //       intl.formatMessage({id: 'GOOD.JOB'}),
  //       intl.formatMessage({id: 'COMPANY.EDIT.SUCCESS'}),
  //       'success'
  //     ).then(() => {
  //       dispatch(redux.actions.resetMessages())
  //     })
  //   }
  //   if (error == 'update_company') {
  //     Swal.fire(
  //       intl.formatMessage({id: 'ERROR'}),
  //       intl.formatMessage({id: 'COMPANY.EDIT.ERROR'}),
  //       'error'
  //     ).then(() => {
  //       dispatch(redux.actions.resetMessages())
  //     })
  //   }
  // }, [success, error])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{'Add  Company'}</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>{'Logo'}</label>
              <div className='col-lg-8'>
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{backgroundImage: `url(${toAbsoluteUrl('/media/logos/blank.png')})`}}
                >
                  <div
                    className='image-input-wrapper w-125px h-125px'
                    style={{
                      backgroundImage: `url(${
                        image ? image : toAbsoluteUrl('/media/logos/blank.png')
                      })`,
                    }}
                  ></div>
                  <label
                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='change'
                    data-bs-toggle='tooltip'
                    title='Change avatar'
                  >
                    <i className='bi bi-pencil-fill fs-7'></i>
                    <input
                      type='file'
                      name='avatar'
                      accept='.png, .jpg, .jpeg'
                      onChange={imageHandler}
                    />
                    <input type='hidden' name='avatar_remove' />
                  </label>
                  <span
                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='cancel'
                    data-bs-toggle='tooltip'
                    title='Cancel avatar'
                  >
                    <i className='bi bi-x fs-2'></i>
                  </span>
                </div>
                <div className='form-text'>
                  Height and width should be equals for prefered size: 70px*70px
                </div>
                <div className='form-text'>Allowed file types: png, jpg, jpeg.</div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>{'NAME'}</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder={'COMPANY NAME'}
                  {...formik.getFieldProps('name')}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger'>{formik.errors.name}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span>{'ACTIVITY DOMAIN'}</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('domain_of_activity')}
                >
                  <option value=''>{'SELECT ACTIVITY DOMAIN'}</option>
                  {categories
                    .filter((data: any) => data.type == 'domain')
                    .map((category: any) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                </select>
                {formik.touched.domain_of_activity && formik.errors.domain_of_activity && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger'>
                      {formik.errors.domain_of_activity}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span>{'COMPANY EMAIL'}</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder={'ADD COMPANY EMAIL'}
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger'>{formik.errors.email}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span>{'PHONE'}</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder={'ADD.COMPANY PHONE'}
                  {...formik.getFieldProps('phone')}
                />
                {formik.touched.phone && formik.errors.phone && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger'>{formik.errors.phone}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span>{'COMPANY WEBSITE'}</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder={'ADD COMPANY.WEBSITE'}
                  {...formik.getFieldProps('website')}
                />
                {formik.touched.website && formik.errors.website && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger'>{formik.errors.website}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span>{'COUNTRY'}</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('country_id')}
                >
                  <option value=''>{'SELECT COUNTRY'}...</option>
                  {countriesData.map((country: any) => (
                    <option key={country.id} value={country.id}>
                      {country.name}
                    </option>
                  ))}
                </select>
                {formik.touched.country_id && formik.errors.country_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger'>{formik.errors.country_id}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span>{'CITY'}</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('city_id')}
                >
                  <option value=''>{'SELECT CITY'}...</option>
                  {cities.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.name}
                    </option>
                  ))}
                </select>
                {formik.touched.city_id && formik.errors.city_id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger'>{formik.errors.city_id}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span>{'COMPANY SIZE'}</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('size')}
                >
                  <option value=''>{'SELECT COMPANY SIZE'}</option>
                  <option value='1-9'>1-9 {'EMPLOYEE'}</option>
                  <option value='10-99'>10-99 {'EMPLOYEE'}</option>
                  <option value='100-999'>100-999 {'EMPLOYEE'}</option>
                  <option value='1000-9999'>1000-9999 {'EMPLOYEE'}</option>
                </select>
                {formik.touched.size && formik.errors.size && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger'>{formik.errors.size}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span>{'REGISTRATION NUMBER'}</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <div className='position-relative'>
                  <span style={{width: '100%'}} className='btn btn-light btn-active-light-primary'>
                    <label
                      className=' text-hover-primary fs-5 fw-bold cursor-pointer '
                      data-kt-image-input-action='change'
                    >
                      {!registrationNumber.loading &&
                        !registrationNumber.name &&
                        'UPLOAD REGISTRATION NUMBER'}
                      {!registrationNumber.loading && registrationNumber.name}
                      {registrationNumber.loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          {'PLEASE WAIT'}...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                      <input
                        type='file'
                        style={{width: '0', height: '0', opacity: '0', overflow: 'hidden'}}
                        name='avatar'
                        accept='.png, .jpg, .jpeg, .pdf'
                        onChange={(e: any) => {
                          fileHandler(e.target.files[0])
                        }}
                      />
                    </label>
                  </span>
                  {!registrationNumber.loading && registrationNumber.name && (
                    <span
                      className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow position-absolute z-index-1 ms-n5 mt-n3'
                      data-bs-toggle='tooltip'
                      title='Cancel avatar'
                      onClick={(e: any) => {
                        setRegistrationNumber({...registrationNumber, link: '', name: ''})
                      }}
                    >
                      <i className='bi bi-x fs-2'></i>
                    </span>
                  )}
                </div>
                {registrationNumber.link ? (
                  <>
                    <span className='form-text'>{'VIEW.FILE'} :</span>
                    <a
                      href={registrationNumber?.link}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='form-text text-hover-primary'
                    >
                      {registrationNumber.name}
                    </a>
                  </>
                ) : null}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span>{'DESCRIPTION'}</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <textarea
                  rows={3}
                  className='form-control form-control-lg form-control-solid'
                  placeholder={'ADD COMPANY.DESCRIPTION'}
                  {...formik.getFieldProps('description')}
                />
                {formik.touched.description && formik.errors.description && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block text-danger'>{formik.errors.description}</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'SAVE.CHANGES'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {'PLEASE WAIT'}...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
      {/* <!--begin::Modal - Select Location--> */}
      <div className='modal fade' id='kt_modal_select_location' tabIndex={-1}>
        <div className='modal-dialog mw-1000px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2>Select Location</h2>
              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <span className='svg-icon svg-icon-1'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <rect
                      opacity='0.5'
                      x='6'
                      y='17.3137'
                      width='16'
                      height='2'
                      rx='1'
                      transform='rotate(-45 6 17.3137)'
                      fill='black'
                    />
                    <rect
                      x='7.41422'
                      y='6'
                      width='16'
                      height='2'
                      rx='1'
                      transform='rotate(45 7.41422 6)'
                      fill='black'
                    />
                  </svg>
                </span>
              </div>
            </div>
            <div className='modal-body'>
              <div className='w-100 rounded' style={{height: '450px'}}>
                MAP{' '}
              </div>
            </div>
            <div className='modal-footer d-flex justify-content-end'>
              <a href='#' className='btn btn-active-light me-5' data-bs-dismiss='modal'>
                Cancel
              </a>
              <button
                type='button'
                id='kt_modal_select_location_button'
                className='btn btn-primary'
                data-bs-dismiss='modal'
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <!--end::Modal - Select Location--> */}
    </div>
  )
}

export {CreateCompany}
