import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
export const CategoryURL = `${API_URL}/categories/`
export const GetDomainURL = `${API_URL}/categories?filter={"type":{"op":"=","value":"domain"}}`
export const GetDepartmentURL = `${API_URL}/categories?filter={"type":{"op":"=","value":"department"}}`
export const GetPosteURL = `${API_URL}/categories?filter={"type":{"op":"=","value":"poste"}}`
export const GetUnitURL = `${API_URL}/categories?filter={"type":{"op":"=","value":"unit"}}`
export const GetProcessURL = `${API_URL}/categories?filter={"type":{"op":"=","value":"process"}}`

export function getDomainsApi(page: any, filter?: any) {
  if (!filter) 
  return axios.get(GetDomainURL)
  else
  return axios.get(
    `${API_URL}/categories/paginate?filter=${filter}&page=${page}&perPage=20`
  )
}
export function getDepartmentsApi(page: any, filter?: any) {
  if (!filter) 
  return axios.get(GetDepartmentURL)
  else
  return axios.get(
    `${API_URL}/categories/paginate?filter=${filter}&page=${page}&perPage=20`
  )
}
export function getUnitsApi(page: any, filter?: any) {
  if (!filter) 
  return axios.get(GetUnitURL)
  else
  return axios.get(
    `${API_URL}/categories/paginate?filter=${filter}&page=${page}&perPage=20`
  )
}
export function getProcessApi(page: any, filter?: any) {
  if (!filter) 
  return axios.get(GetProcessURL)
  else
  return axios.get(
    `${API_URL}/categories/paginate?filter=${filter}&page=${page}&perPage=20`
  )
}
export function getPostesApi(page: any, filter?: any) {
  if (!filter) 
  return axios.get(GetPosteURL)
  else
  return axios.get(
    `${API_URL}/categories/paginate?filter=${filter}&page=${page}&perPage=20`
  )
}
export function getCategory(id: any) {
  return axios.get(CategoryURL+id)
}

export function createCategory(name: string, name_ar: string, type: string, parent_id?: number) {
  return axios.post<any>(CategoryURL, {
    name: name,
    name_ar: name_ar,
    type: type,
    parent_id: parent_id
  })
}

export function editCategory(data: any) {
  return axios.patch<any>(CategoryURL+data.id, data)
}
export function deleteCategory(id: any) {
  return axios.delete<any>(CategoryURL+id)
}
