/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useParams} from 'react-router-dom'
import {RootState} from '../../../../../../setup'
import {KTSVG} from './../../../../../../_metronic/helpers'
import {DataTable} from './TablesWidget5'
import * as redux from '../../../redux/UserRedux'

export function Overview() {
  const dispatch = useDispatch()
  const params: any = useParams()
  const user: any = useSelector<RootState>(({user}) => user.user)
  const invitations = [{company_name : 'Accelerant', status: 'accepted', date: '2022-03-10T15:18:36.440+00:00'},
  {company_name : 'Google', status: 'refused', date: '2022-03-05T15:18:36.440+00:00'},
  {company_name : 'Facebook', status: 'pending', date: '2022-02-22T15:18:36.440+00:00'}]
  const applications = [{company_name : 'Xiaomi', status: 'pending', date: '2022-01-10T15:18:36.440+00:00'},
  {company_name : 'Samsung', status: 'pending', date: '2022-03-25T15:18:36.440+00:00'},
  {company_name : 'Apple', status: 'pending', date: '2022-02-28T15:18:36.440+00:00'}]
  useEffect(() => {
    dispatch(redux.actions.getUser({id: params.id}))
  }, [])
  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Account Details</h3>
          </div>
        </div>

        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Full Name</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                {user.first_name + ' ' + user.last_name}
              </span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Contact Email</label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{user.email || '-'}</span>

              {
                //<span className='badge badge-success'>Verified</span>
              }
            </div>
          </div>

<div className='row mb-7'>
  <label className='col-lg-4 fw-bold text-muted'>Birth Date</label>

  <div className='col-lg-8 d-flex align-items-center'>
    <span className='fw-bolder fs-6 me-2'>{user.birthday || '-'}</span>

    {
      //<span className='badge badge-success'>Verified</span>
    }
  </div>
</div>

<div className='row mb-7'>
  <label className='col-lg-4 fw-bold text-muted'>Cin</label>

  <div className='col-lg-8 d-flex align-items-center'>
    <span className='fw-bolder fs-6 me-2'>{user.cin || '-'}</span>

    {
      //<span className='badge badge-success'>Verified</span>
    }
  </div>
</div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Contact Phone</label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{user.phone || '-'}</span>

              {
                //<span className='badge badge-success'>Verified</span>
              }
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Height</label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{user.height || '-'}</span>

              {
                //<span className='badge badge-success'>Verified</span>
              }
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Disponibility</label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{user.disponibility || '-'}</span>

              {
                //<span className='badge badge-success'>Verified</span>
              }
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Can work standing</label>

            <div className='col-lg-8 d-flex align-items-center'>
              <div
                className='form-check form-switch fv-row'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_1'
              >
                <input
                  className='form-check-input w-45px h-30px'
                  type='checkbox'
                  id='allowmarketing'
                  disabled={true}
                  checked={user.can_work_standing}
                />
                <label className='form-check-label'></label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row gy-10 gx-xl-10'>

        <div className='col-xl-6'>
          <DataTable data = {invitations} title='Interview Invitations' className='card-xxl-stretch mb-5 mb-xl-10' />
        </div>
        <div className='col-xl-6'>
          <DataTable data = {applications} title='List of Applications' className='card-xxl-stretch mb-5 mb-xl-10' />
        </div>
      </div>
    </>
  )
}
